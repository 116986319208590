import {
  deleteApi,
  getDetailsApi,
  getShootsDetailsApi,
  updateApi,
} from "./api.request";

const SHOOTS_PATH = "/apis/shoot";

export const getShoots = async (
  search,
  fromDate,
  toDate,
  limit,
  offset,
  status,
  hospital
) =>
  getShootsDetailsApi(
    `${SHOOTS_PATH}`,
    search,
    fromDate,
    toDate,
    limit,
    offset,
    status,
    hospital
  );

export const viewShoot = async (shootId) =>
  getDetailsApi(`${SHOOTS_PATH}/${shootId}`);
export const deleteShoot = async (shootId) =>
  deleteApi(`${SHOOTS_PATH}/${shootId}`);
export const updatePackageAmount = async (shootId, body) =>
  updateApi(`${SHOOTS_PATH}/package/${shootId}`, body);
