import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MapModal from "src/components/map/Map.Modal";
import { createUser, getUsers } from "src/service/user.service";
import Schema from "src/components/Joi.validation/User.create.schema.validation";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  dialogOpen,
  handleDialogClose,
  fetchData,
  setPage,
  setFetchedPages,
}) {
  const [open, setOpen] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState({});
  const [selectedPlace, setSelectedPlace] = React.useState(null);
  const [assignedLocation, setAssignedLocation] = React.useState({
    type: "POINT",
    coordinates: [],
  });
  const [input, setInput] = React.useState({});
  const [selectLocation, setSelectLocation] = React.useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };
  const dataSendingToBackend = {
    ...input,
    locationVerificationRequired: selectLocation,
    ...(selectLocation && { assignedLocation }),
  };
  const resetForm = () => {
    setInput({
      fullName: "",
      pass: "",
      email: "",
      phNo: "",
      empCode: "",
    });
    setSelectLocation(true);
    setSelectedPlace(null);
    setAssignedLocation({ type: "POINT", coordinates: [] });
  };

  const addNewUser = async () => {
    const { error, value } = Schema.validate(dataSendingToBackend);
    // console.log(dataSendingToBackend, ...assignedLocation);
    if (error) {
      console.log(error);
      const errors = error.details.reduce((acc, curr) => {
        return { ...acc, [curr.context.key]: curr.message };
      }, {});
      setValidationErrors(errors);

      return false;
    } else {
      setValidationErrors({});
      if (
        selectLocation === true &&
        assignedLocation.coordinates.length === 0
      ) {
        toast.error("Please search and select desired location and try again!");
        return;
      }
      const { data } = await createUser(dataSendingToBackend);
      // if(data && data.code === 400){
      //   console.log(data?.message);
      //   toast.error(data.message);
      // }
      if (data?.code === 200) {
        toast.success("New user created!");
        setPage(0);
        setFetchedPages(1);
        fetchData("", "", 0, "");
        handleDialogClose();
        resetForm();
        console.log(data);
      } else {
        toast.error("Please check the input values and try again!");
      }
    }
  };

  React.useEffect(() => {
    setInput((prevInput) => ({
      ...prevInput,

      assignedLocation: assignedLocation,
    }));
    resetForm();
  }, [assignedLocation.resetForm]);

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
          {"Add New User"}
        </DialogTitle>
        <DialogContent>
          <Alert severity="info">
            Please fill the details and click the save button to add new user.
          </Alert>
          <Box sx={{ marginTop: 2 }}>
            <Grid container spacing={2}>
              <Grid
                sx={{ display: "flex", justifyContent: "center" }}
                item
                xs={6}
              >
                <TextField
                  error={Boolean(validationErrors.fullName)}
                  helperText={validationErrors.fullName}
                  id="standard-error-helper-text"
                  name="fullName"
                  value={input.fullName}
                  onChange={handleInput}
                  label="Full Name"
                  required
                  fullWidth
                />
              </Grid>
              <Grid
                sx={{ display: "flex", justifyContent: "center" }}
                item
                xs={6}
              >
                <TextField
                  error={Boolean(validationErrors.pass)}
                  helperText={validationErrors.pass}
                  id="standard-error-helper-text"
                  name="pass"
                  value={input.pass || ""}
                  onChange={handleInput}
                  label="Password"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(validationErrors.email)}
                  helperText={validationErrors.email}
                  id="standard-error-helper-text"
                  name="email"
                  value={input.email}
                  onChange={handleInput}
                  label="E-mail"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(validationErrors.phNo)}
                  helperText={validationErrors.phNo}
                  id="standard-error-helper-text"
                  name="phNo"
                  value={input.phNo}
                  onChange={handleInput}
                  label="Contact Number"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    error={Boolean(validationErrors.role)}
                    helperText={validationErrors.role}
                    id="standard-error-helper-text"
                    name="role"
                    placeholder="Role"
                    value={input?.role || ""}
                    label="Role *"
                    onChange={handleInput}
                  >
                    <MenuItem value="ADMIN">Admin</MenuItem>
                    <MenuItem value="PHOTOGRAPHER">Photographer</MenuItem>
                    <MenuItem value="EDITOR">Editor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  // sx={{marginLeft:6}}
                  error={Boolean(validationErrors.empCode)}
                  helperText={validationErrors.empCode}
                  id="standard-error-helper-text"
                  required
                  name="empCode"
                  label="Employee Code"
                  value={input.empCode || ""}
                  onChange={handleInput}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Box
                  sx={{ display: "flex", alignItems: "center", marginTop: 1 }}
                >
                  <Checkbox
                    checked={selectLocation}
                    value={selectLocation}
                    onChange={() => setSelectLocation(!selectLocation)}
                    style={{
                      backgroundColor: "white",
                      color: "#74BAB6",
                      borderColor: "#74BAB6",
                    }}
                  />
                  Location
                </Box>
              </Grid>
              <Grid
                // sx={{ display: "flex", justifyContent: "center" }}
                item
                xs={3}
              >
                <Button
                  sx={{ width: "100%", height: "100%" }}
                  variant="contained"
                  disabled={!selectLocation}
                  onClick={handleOpen}
                >
                  {selectedPlace ? "Edit location" : "Select location"}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={!selectLocation}
                  // sx={{marginLeft:6}}
                  // name="empCode"
                  label="location"
                  value={selectedPlace || ""}
                  // onChange={handleInput}
                  fullWidth
                />
              </Grid>
              <MapModal
                open={open}
                setAssignedLocation={setAssignedLocation}
                handleClose={handleClose}
                selectedPlace={selectedPlace}
                setSelectedPlace={setSelectedPlace}
              />
            </Grid>
            <Box display="flex" justifyContent="right" marginTop={6} gap={3}>
              <Button
                variant="outlined"
                style={{
                  backgroundColor: "white",
                  color: "#EFA8B0",
                  borderColor: "#EFA8B0",
                }}
                onClick={() => {
                  handleDialogClose();
                  resetForm();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                style={{
                  backgroundColor: "white",
                  color: "#74BAB6",
                  borderColor: "#74BAB6",
                }}
                onClick={addNewUser}
              >
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}
