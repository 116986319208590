import { getAllClients } from "src/service/clients.service";

const { createContext, useState } = require("react");

const ClientsContext = createContext();
export const ClientsContextProvider = ({ children }) => {
  const [clientData, setClientData] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(0);
  const [fetchedPages, setFetchedPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (filterName, offset = 0) => {
    setIsLoading(true);
    const { data } = await getAllClients(filterName, rowsPerPage, offset);
    setIsLoading(false);
    if (data?.data?.pagination?.offset === "0")
      setClientData(data?.data?.items);
    else {
      setClientData((preValue) => {
        console.log("trying to get clients array", [
          ...preValue,
          ...data?.data?.items,
        ]);
        return [...preValue, ...data?.data?.items];
      });
    }
    console.log(data?.data?.items);
    setPagination(data?.data?.pagination);
  };

  return (
    <ClientsContext.Provider
      value={{
        clientData,
        setClientData,
        fetchData,
        pagination,
        setPagination,
        rowsPerPage,
        setRowsPerPage,
        fetchedPages,
        page,
        setPage,
        setFetchedPages,
        status,
        setStatus,
        filter,
        setFilter,
        search,
        setSearch,
        isLoading,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};
export default ClientsContext;
