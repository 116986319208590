import { createApi, deleteApi, getDetailsApi, getUsersApi, isEnabledApi, resetPassApi, updateApi } from "./api.request";

const USER_PATH = '/apis/user';

export const createUser = async (body)=> createApi(`${USER_PATH}`, body);

export const getUsers = async (search, role, limit, offest, status) => getUsersApi(`${USER_PATH}/search`,search, role, limit, offest, status);

export const deleteUser = async (userId)=> deleteApi(`${USER_PATH}/${userId}`);

export const viewUser = async (userId) => getDetailsApi(`${USER_PATH}/${userId}`);

export const updateUser = async (userId, body) => updateApi(`${USER_PATH}/${userId}`, body);

export const isEnabledUser = async (userId, body) => isEnabledApi(`${USER_PATH}/${userId}/enable`, body);
export const resetPasswordUser = async (userId, body) => resetPassApi(`${USER_PATH}/reset-password/${userId}`, body);