import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import TicketListContext from "src/context/Ticket/Ticket.Context.Provider";

export default function TicketFilterDialogContent({ onClose }) {
  const { setPage, setFetchedPages, setStatus } = useContext(TicketListContext);

  const filteredStatusList = ["Created", "In Progress", "Resolved"];
  const [activeStatus, setActiveStatus] = useState("");
  const handleApply = () => {
    if (activeStatus === "Created") {
      setStatus("Created");
    } else if (activeStatus === "In Progress") {
      setStatus("In Progress");
    } else if (activeStatus === "Resolved") {
      setStatus("Resolved");
    }
    setPage(0);
    setFetchedPages(1);
    toast("Filter applied successfully", {
      position: "top-right",
      autoClose: 3579,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      type: "success",
    });
    onClose();
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            onChange={(e, newValue) => {
              setActiveStatus(newValue);
            }}
            value={activeStatus}
            name="isActive"
            options={filteredStatusList}
            renderInput={(params) => (
              <TextField {...params} label="Status" required />
            )}
            isOptionEqualToValue={(option, value) =>
              value === undefined || value === "" || option?._id === value?._id
            }
            sx={{
              "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
                {
                  backgroundColor: "#fff",
                },
              "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'].Mui-focused":
                {
                  backgroundColor: "#f6f6f6",
                },
            }}
          />
        </Grid>
      </Grid>
      <Box position="absolute" right="25px" bottom="25px">
        <Button
          onClick={onClose}
          variant="outlined"
          style={{
            backgroundColor: "white",
            color: "#EFA8B0",
            borderColor: "#EFA8B0",
            marginRight: "4px",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          style={{
            backgroundColor: "white",
            color: "#74BAB6",
            borderColor: "#74BAB6",
          }}
          onClick={handleApply}
        >
          Apply
        </Button>
      </Box>
    </>
  );
}
