const { getClientsApi, getDetailsApi, deleteApi } = require("./api.request");

const CLIENTS_PATH = "/apis/client";

export const getAllClients = async (search, limit, offset) =>
  getClientsApi(`${CLIENTS_PATH}/search`, search, limit, offset);

export const viewClient = async (clientId) =>
  getDetailsApi(`${CLIENTS_PATH}/${clientId}`);
export const deleteClient = async (clientId) =>
  deleteApi(`${CLIENTS_PATH}/${clientId}`);
