import PropTypes from "prop-types";
// material
import { styled, alpha } from "@mui/material/styles";
import {
  Toolbar,
  Tooltip,
  IconButton,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import { Box } from "@mui/material";
import { useContext, useState } from "react";
import Filterdialog from "src/components/filter-dialog/Filter.Dialog";
import ReplayIcon from "@mui/icons-material/Replay";
import TicketListContext from "src/context/Ticket/Ticket.Context.Provider";
import { toast } from "react-toastify";

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 321,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

TicketListToolbar.propTypes = {
  search: PropTypes.string,
  onSearch: PropTypes.func,
};

export default function TicketListToolbar({ search, onSearch, fetchData }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { setPage, setFetchedPages, setStatus } = useContext(TicketListContext);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const resetHandler = () => {
    setStatus("");
    toast("Reset applied filter", {
      position: "top-right",
      autoClose: 3579,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      type: "warning",
    });
  };

  return (
    <StyledRoot>
      <StyledSearch
        value={search}
        onChange={onSearch}
        placeholder="Search By Ticket No"
        startAdornment={
          <Tooltip title="search" arrow>
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{ color: "text.disabled", width: 20, height: 20 }}
              />
            </InputAdornment>
          </Tooltip>
        }
      />
      <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
        <Tooltip title="Reset Filter" arrow>
          <IconButton>
            <ReplayIcon onClick={resetHandler} />
          </IconButton>
        </Tooltip>
        <Filterdialog
          open={dialogOpen}
          onClose={handleDialogClose}
          module="ticket"
        />

        <Box onClick={handleDialogOpen}>
          <Tooltip title="Filter list" arrow>
            <IconButton>
              <Iconify icon="ic:round-filter-list" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </StyledRoot>
  );
}
