import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Alert,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { updateOrder, viewOrder } from "src/service/order.service";
import SkeletonLoader from "src/components/skeleton/Skeleton.Loader";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Orderview() {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [open, setOpen] = React.useState(false);
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchOrderDetails = async () => {
    setIsLoading(true);
    const { data } = await viewOrder(orderId);
    setInputs(data?.data);
    setIsLoading(false);
  };
  const options = {
    timeZone: "Asia/Kolkata",
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const handleChange = (event) => {
    setDeliveryStatus(event.target.value);
    setIsStatusChanged(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const deliveryHandler = async () => {
    const body = {
      status: deliveryStatus,
    };
    const { data } = await updateOrder(orderId, body);
    setIsStatusChanged(!isStatusChanged);
    fetchOrderDetails();
    handleClose();
  };

  useEffect(() => {
    fetchOrderDetails();
  }, []);
  useEffect(() => {
    if (inputs?.status && inputs?.status !== "") {
      setDeliveryStatus(inputs?.status);
    }
  }, [inputs]);
  return (
    <>
      <Helmet>
        <title> Order's details | Camories </title>
      </Helmet>
      <Card key={inputs?._id} variant="outlined" sx={{ background: "#f1f2f6" }}>
        {console.log(inputs, "gudum")}
        {isLoading ? (
          <SkeletonLoader />
        ) : (
          <CardContent>
            <Typography variant="h4" marginBottom={3} color="primary">
              Order Details{console.log(inputs)}
            </Typography>
            <Card>
              <CardContent>
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "right" }}>
                    <Button
                      variant="contained"
                      sx={{ mt: 2, mb: 3, mr: 2, py: 1, px: 2 }}
                      onClick={() => navigate(-1)}
                      style={{
                        backgroundColor: "white",
                        color: "#EFA8B0",
                        borderColor: "#EFA8B0",
                      }}
                    >
                      <ArrowBackIcon />
                      Back
                    </Button>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.shoot?.shootNo || ""}
                        name="name"
                        label="Shoot No"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={
                          new Date(inputs?.shoot?.shootDate).toLocaleString(
                            "en-US",
                            options
                          ) || ""
                        }
                        name="shootDate"
                        label="Shoot Date."
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.shoot?.client?.name || ""}
                        name="name"
                        label="Client Name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.shoot?.client?.phoneNo || ""}
                        name="name"
                        label="Client Phone No."
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.shoot?.client?.email || ""}
                        name="name"
                        label="Client Email Id"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.shoot?.hospital?.name || ""}
                        name="name"
                        label="Hospital Name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.shoot?.createdBy?.fullName || ""}
                        name="name"
                        label="Created By"
                        fullWidth
                      />
                    </Grid>
                    {inputs?.client && (
                      <Grid item xs={6}>
                        <TextField
                          value={inputs?.client?.email || ""}
                          name="email"
                          label="E-mail ID"
                          fullWidth
                        />
                      </Grid>
                    )}

                    <Grid item xs={6}>
                      <TextField
                        value={
                          new Date(inputs?.softCopyDeliveryDate).toLocaleString(
                            "en-US",
                            options
                          ) || ""
                        }
                        name="name"
                        label="Soft Copy Delivery Date"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={
                          new Date(inputs?.frameDeliveryDate).toLocaleString(
                            "en-US",
                            options
                          ) || ""
                        }
                        name="name"
                        label="Frame Delivery Date"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={
                          inputs?.shoot?.packageAmount !== undefined &&
                          inputs?.shoot?.packageAmount !== null
                            ? inputs?.shoot?.packageAmount
                            : ""
                        }
                        name="status"
                        label="Package Amount (₹)"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.status || ""}
                        name="status"
                        label="Order Status"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.remarks || ""}
                        name="remarks"
                        label="Remarks"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "right", mt: 2 }}>
                  {/* <Button variant="outlined">View Order</Button> */}
                </Box>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: 2 }}>
              <CardContent>
                <Typography variant="h5" marginBottom={3} color="primary">
                  Soft Copy Details
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 3,
                  }}
                >
                  <Typography variant="subtitle1">
                    No. of Soft Copies
                  </Typography>
                  <Typography variant="subtitle1">
                    {inputs?.noOfSoftCopies}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",

                    marginBottom: 1,
                  }}
                >
                  <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                    Image Details
                  </Typography>
                  {/* <Typography>{`Image ${+1}`}</Typography> */}
                  <Button
                    variant="outlined"
                    href={inputs?.softCopyLinks}
                    target="_blank"
                  >
                    Download
                  </Button>
                </Box>
              </CardContent>
            </Card>
            {inputs?.complementaryFrame && (
              <Card sx={{ marginTop: 2 }}>
                <CardContent>
                  <Typography variant="h5" marginBottom={3} color="primary">
                    Complementary Frame Details
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 2,
                    }}
                  >
                    <Typography variant="subtitle1">Frame Size</Typography>
                    <Typography variant="subtitle1">
                      {inputs?.complementaryFrame?.frameSize}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 2,
                    }}
                  >
                    <Typography>Image</Typography>
                    <Button
                      variant="outlined"
                      href={inputs?.complementaryFrame?.frameImage}
                      target="_blank"
                    >
                      Download
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            )}
            {inputs?.additionalFrames?.length > 0 && (
              <Card sx={{ marginTop: 2 }}>
                <CardContent>
                  <Typography variant="h5" marginBottom={3} color="primary">
                    Additional Frame Details
                  </Typography>
                  {inputs?.additionalFrames?.map((item, index) => {
                    return (
                      <>
                        <Typography
                          variant="subtitle1"
                          sx={{ marginBottom: 2 }}
                        >{`Frame ${index + 1}`}</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: 1,
                            marginInline: 2,
                          }}
                        >
                          <Typography>{"Frame Size"}</Typography>
                          <Typography sx={{ marginRight: 4 }}>
                            {item?.frameSize}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: 2,
                            marginInline: 2,
                          }}
                        >
                          <Typography>{"Image"}</Typography>
                          <Button
                            variant="outlined"
                            href={item?.frameImage}
                            target="_blank"
                          >
                            Download
                          </Button>
                        </Box>
                      </>
                    );
                  })}
                </CardContent>
              </Card>
            )}
            {inputs?.status !== "Frame Received In Hospital" && (
              <Card sx={{ marginTop: 2 }}>
                <CardContent>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={deliveryStatus}
                        label="Age"
                        onChange={handleChange}
                      >
                        <MenuItem value={"Soft Copy Delivered"}>
                          Soft Copy Delivered
                        </MenuItem>
                        <MenuItem value={"Frame Sent To Hospital"}>
                          Frame Sent To Hospital
                        </MenuItem>
                      </Select>
                    </FormControl>

                    {/* {inputs?.status === "Ready For Delivery" ? (
                  <Button variant="outlined" disabled>
                    Marked As Ready For Delivery
                  </Button>
                ) : (
                  <Button variant="contained" onClick={deliveryHandler}>
                    Mark As Ready For Delivery
                  </Button>
                )} */}
                    {deliveryStatus !== "" && (
                      <Button
                        sx={{ marginLeft: 1 }}
                        variant="outlined"
                        onClick={handleClickOpen}
                        disabled={!isStatusChanged}
                      >
                        Update
                      </Button>
                    )}
                  </Box>
                  <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle>{"Are You Sure?"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to update the status to{" "}
                        {deliveryStatus}?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button onClick={deliveryHandler}>Confirm</Button>
                    </DialogActions>
                  </Dialog>
                </CardContent>
              </Card>
            )}
            {inputs?.status === "Frame Received In Hospital" && (
              <Alert sx={{ marginTop: 2 }} severity="success">
                <Typography variant="subtitle1">
                  Frame Received In Hospital.
                </Typography>
              </Alert>
            )}
          </CardContent>
        )}
      </Card>
    </>
  );
}
