import { getAttendances } from "src/service/attendance.service";

const { createContext, useState } = require("react");

const AttendanceListContext = createContext();
export const AttendanceListContextProvider = ({ children }) => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(0);
  const [fetchedPages, setFetchedPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [empCode, setEmpCode] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (Code, startDate, dueDate, offset = 0) => {
    setIsLoading(true);
    const { data } = await getAttendances(
      Code,
      startDate,
      dueDate,
      rowsPerPage,
      offset
    );
    const res = await getAttendances(
      Code,
      startDate,
      dueDate,
      rowsPerPage,
      offset
    );
    console.log(res, "result");
    setIsLoading(false);
    if (data?.data?.pagination?.offset === "0")
      setAttendanceData(data?.data?.items);
    else {
      setAttendanceData((preValue) => {
        console.log("trying to get hospityal array", [
          ...preValue,
          ...data?.data?.items,
        ]);
        return [...preValue, ...data?.data?.items];
      });
    }
    console.log(data?.data?.items);
    console.log(fromDate, toDate);

    setPagination(data?.data?.pagination);
  };

  return (
    <AttendanceListContext.Provider
      value={{
        attendanceData,
        setAttendanceData,
        fetchData,
        pagination,
        setPagination,
        rowsPerPage,
        setRowsPerPage,
        fetchedPages,
        page,
        setPage,
        setFetchedPages,
        status,
        setStatus,
        filter,
        setFilter,
        search,
        setSearch,
        isLoading,
        empCode,
        setEmpCode,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
      }}
    >
      {children}
    </AttendanceListContext.Provider>
  );
};
export default AttendanceListContext;
