import dayjs from "dayjs";
import { getSalesAmount, getSalesPackage } from "src/service/analytics.service";

const { createContext, useState } = require("react");

const SalesAnalyticsContext = createContext();
export const SalesAnalyticsContextProvider = ({ children }) => {
  const [filter, setFilter] = useState("");
  const [employee, setEmployee] = useState("");
  const [hospital, setHospital] = useState("");
  const [fromDate, setFromDate] = useState(
    dayjs().subtract(31, "days").startOf("day").valueOf()
  );
  const [toDate, setToDate] = useState(dayjs().endOf("day").valueOf());

  const [status, setStatus] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [startDate, setStartDate] = useState(
    dayjs().subtract(31, "days").startOf("day").valueOf()
  );
  const [endDate, setEndDate] = useState(dayjs().endOf("day").valueOf());

  const [salesAmountData, setSalesAmountData] = useState([]);
  const [salesAmountDate, setSalesAmountDate] = useState([]);
  const [salesPackageData, setSalesPackageData] = useState([]);
  const [salesPackageDate, setSalesPackageDate] = useState([]);
  const [averageSales, setAverageSales] = useState(null);
  const [totalSales, setTotalSales] = useState(null);
  const options = {
    timeZone: "Asia/Kolkata",
    day: "2-digit",
    month: "short",
    year: "numeric",
  };

  const fetchAmountData = async (
    fromDateFilter,
    toDateFilter,
    statusFilter
  ) => {
    const { data } = await getSalesAmount(
      fromDateFilter,
      toDateFilter,
      statusFilter
    );
    console.log(data, "graph");
    setSalesAmountData(
      data?.data?.salesAnalyticsData?.map((item) => item?.amount)
    );
    setAverageSales(data?.data?.averageSales);
    setTotalSales(data?.data?.totalSales);
    setSalesAmountDate(
      data?.data?.salesAnalyticsData?.map((item) =>
        new Date(item?.shootDate).toLocaleString("en-US", options)
      )
    );
  };

  const fetchPackageData = async (
    fromDateFilter,
    toDateFilter,
    statusFilter
  ) => {
    const { data } = await getSalesPackage(
      fromDateFilter,
      toDateFilter,
      statusFilter
    );
    console.log(data, "graph");
    setSalesPackageData(data?.data?.map((item) => item?.count));
    setSalesPackageDate(data?.data?.map((item) => item?.packageAmount));
  };
  // console.log(salesPackageData, salesPackageDate, 'package');

  return (
    <SalesAnalyticsContext.Provider
      value={{
        salesAmountData,
        salesAmountDate,
        salesPackageData,
        salesPackageDate,
        fetchAmountData,
        fetchPackageData,
        filter,
        setFilter,
        averageSales,
        employee,
        setEmployee,
        hospital,
        setHospital,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        totalSales,
        status,
        setStatus,
        employeeName,
        setEmployeeName,
        hospitalName,
        setHospitalName,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
      }}
    >
      {children}
    </SalesAnalyticsContext.Provider>
  );
};
export default SalesAnalyticsContext;
