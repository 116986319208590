const {
  getOrderDetailsApi,
  getDetailsApi,
  updateApi,
  deleteApi,
} = require("./api.request");

const ORDER_PATH = "/apis/order";

export const getOrders = async (
  search,
  limit,
  offset,
  status,
  hospital,
  frameDeliveryDate,
  softCopyDeliveryDate
) =>
  getOrderDetailsApi(
    `${ORDER_PATH}`,
    search,
    limit,
    offset,
    status,
    hospital,
    frameDeliveryDate,
    softCopyDeliveryDate
  );

export const viewOrder = async (orderId) =>
  getDetailsApi(`${ORDER_PATH}/${orderId}`);
export const updateOrder = async (orderId, body) =>
  updateApi(`${ORDER_PATH}/${orderId}`, body);
export const deleteOrder = async (orderId) =>
  deleteApi(`${ORDER_PATH}/${orderId}`);
