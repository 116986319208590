/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from "react-helmet-async";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  Checkbox,
} from "@mui/material";
import Iconify from "../../components/Iconify";
import { viewUser } from "src/service/user.service";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Stack from "@mui/material/Stack";
import SkeletonLoader from "src/components/skeleton/Skeleton.Loader";
const UserView = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({});

  const fetchData = async () => {
    setIsLoading(true);
    const { data } = await viewUser(userId);
    setInputs(data?.data || {});
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = () => {
    navigate(`/users/${userId}/edit`);
  };

  return (
    <>
      <Helmet>
        <title>
          {" "}
          {inputs?.fullName?.split(" ")[0] || "User"}'s detail | Camories{" "}
        </title>
      </Helmet>
      <Card key={inputs?._id} variant="outlined" sx={{ background: "#f1f2f6" }}>
        <CardContent>
          <Typography variant="h4" marginBottom={3} color="primary">
            User Details
          </Typography>
          {isLoading ? (
            <SkeletonLoader />
          ) : (
            <Card>
              <CardContent>
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "right" }}>
                    <Button
                      variant="contained"
                      sx={{ mt: 2, mb: 3, mr: 2, py: 1, px: 2 }}
                      style={{
                        backgroundColor: "white",
                        color: "#EFA8B0",
                        borderColor: "#EFA8B0",
                      }}
                      onClick={() => navigate(-1)}
                    >
                      <ArrowBackIcon />
                      Back
                    </Button>

                    <Button
                      variant="contained"
                      startIcon={<Iconify icon="eva:edit-fill" />}
                      sx={{ mt: 2, mb: 3, py: 1, px: 2, float: "right" }}
                      style={{
                        backgroundColor: "white",
                        color: "#74BAB6",
                        borderColor: "#74BAB6",
                      }}
                      onClick={handleEdit}
                    >
                      Edit
                    </Button>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        value={inputs?.empCode || ""}
                        disabled
                        name="empCode"
                        label="Employee Code"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.fullName || ""}
                        disabled
                        name="fullName"
                        label="Full Name"
                        fullWidth
                        required
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.email || ""}
                        disabled
                        name="email"
                        label="Email"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.phNo || ""}
                        disabled
                        name="phone"
                        label="Phone"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.role || ""}
                        name="role"
                        label="Role"
                        fullWidth
                        required
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.isActive ? "Active" : "Inactive"}
                        name="isActive"
                        label="Status"
                        fullWidth
                        disabled
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 1,
                        }}
                      >
                        <Checkbox
                          defaultChecked={inputs?.locationVerificationRequired}
                          checked={inputs?.locationVerificationRequired}
                          disabled
                          style={{
                            backgroundColor: "white",
                            color: "#74BAB6",
                            borderColor: "#74BAB6",
                          }}
                        />
                        Location
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default UserView;
