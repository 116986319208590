import { createContext, useState } from "react";
import { getUsers } from "src/service/user.service";

const UserListContext = createContext();

export const UserListContextProvider = ({ children }) => {
  const [userData, setUserData] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(0);
  const [fetchedPages, setFetchedPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [userType, setUserType] = useState("");

  const fetchData = async (
    filterName,
    append = filter,
    offset = 0,
    statusFilter = status,
    isDeleted
  ) => {
    setIsLoading(true);
    const { data } = await getUsers(
      filterName,
      append,
      rowsPerPage,
      offset,
      statusFilter
    );
    setIsLoading(false);
    if (data?.data?.pagination?.offset === "0") {
      console.log("trying to get survey array if block");
      setUserData(data?.data?.items);
    } else if (isDeleted) {
      console.log("inside else if 2nd ");
      console.log(selectedIndex, "this is selected");
      setUserData((prevData) => {
        const newData = [...prevData];
        newData.splice(page * rowsPerPage + selectedIndex, 1);
        return newData;
      });
    } else {
      console.log("inside else");
      setUserData((prevData) => [...prevData, ...data?.data?.items]);
    }
    setPagination(data?.data?.pagination);
    console.log(data?.data, "user data");
  };

  return (
    <UserListContext.Provider
      value={{
        userData,
        setUserData,
        fetchData,
        pagination,
        setPagination,
        rowsPerPage,
        setRowsPerPage,
        fetchedPages,
        page,
        setPage,
        setFetchedPages,
        status,
        setStatus,
        filter,
        setFilter,
        search,
        setSearch,
        userType,
        setUserType,
        isLoading,
        setSelectedIndex,
      }}
    >
      {children}
    </UserListContext.Provider>
  );
};
export default UserListContext;
