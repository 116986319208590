/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from "react-helmet-async";

import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
} from "@mui/material";

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import Iconify from "src/components/Iconify";
import {
  resetPasswordUser,
  updateUser,
  viewUser,
} from "src/service/user.service";
import { toast } from "react-toastify";
import MapModal from "src/components/map/Map.Modal";
import SkeletonLoader from "src/components/skeleton/Skeleton.Loader";

export default function Useredit() {
  const { userId } = useParams();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({});
  const [status, setStatus] = useState(null);
  const [open, setOpen] = useState(false);
  const [resetPasswords, setResetPasswords] = useState({});
  const [openMap, setOpenMap] = useState(false);
  const [selectedPlace, setSelectedPlace] = React.useState(null);
  const [assignedLocation, setAssignedLocation] = React.useState({
    type: "POINT",
    coordinates: [],
  });
  const [selectLocation, setSelectLocation] = useState(false);

  const handleCheckboxChange = () => {
    setSelectLocation(!selectLocation);
  };

  const handleOpenMap = () => {
    setOpenMap(true);
  };
  const handleCloseMap = () => {
    setOpenMap(false);
  };

  inputs.isActive = status;

  const handleInput = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setInputs((values) => ({ ...values, [name]: inputValue }));
  };
  const handleResetPasswordInput = (e) => {
    const { name, value } = e.target;
    setResetPasswords((values) => ({ ...values, [name]: value }));
  };

  const handleStatus = (newValue) => {
    if (newValue === "Active") {
      setStatus(true);
    } else if (newValue === "Inactive") {
      setStatus(false);
    } else setStatus((value) => value);
  };

  //   const { error, value } = Schema.validate(inputs);

  const handleCancel = () => {
    // navigate('/users');
    navigate(-1);
  };
  const dataSendingToBackend = {
    ...inputs,
    locationVerificationRequired: selectLocation,
  };

  //when any user is created using the assignedLocation that is already exist within the inputs which is doesnt depend upon the selectLocation and it is present in the inputs and passed inside payload that is why we need to do below steps it will not delete the assignedLocation from the server it just help to not to pass the assignedLocation object when untick the location checkbox in edit page
  if (selectLocation) {
    dataSendingToBackend.assignedLocation = assignedLocation;
  } else {
    delete dataSendingToBackend.assignedLocation;
  }

  const updateData = async () => {
    console.log(inputs, "select");
    const { data } = await updateUser(userId, dataSendingToBackend);
    if (data?.code === 200) {
      navigate("/users");
      toast.success("User's data Updated Successfully");
    } else {
      toast.error("Data Not Updated");
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const resetPasswordHandler = () => {
    setOpen(true);
  };

  const handleSave = () => {
    // if (error) {
    //   // console.log(error);
    //   error?.details?.forEach((err) => {
    //     toast.error(err.message);
    //   });
    // } else {
    updateData();

    // }
  };
  async function updatePassword() {
    if (resetPasswords.password !== resetPasswords.cpassword) {
      toast.error("Both have different charecters!");
      return;
    } else {
      const { data } = await resetPasswordUser(userId, {
        pass: resetPasswords.password,
      });
      if (data?.code === 200) {
        navigate("/users");
        toast.success("User's Password Updated Successfully");
        handleClose();
      }
    }
  }

  const ActiveInactive = ["Active", "Inactive"];

  const fetchData = async () => {
    setIsLoading(true);
    const { data } = await viewUser(userId);
    // console.log(data?.data);
    setInputs(data?.data || {});
    setStatus(data?.data?.isActive || null);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (inputs?.locationVerificationRequired !== undefined) {
      setSelectLocation(inputs?.locationVerificationRequired);
    }
    console.log(selectLocation, "select");
  }, [inputs]);

  return (
    <>
      <Helmet>
        <title>
          {" "}
          {inputs?.fullName?.split(" ")[0] || "User"}'s edit | Camories{" "}
        </title>
      </Helmet>
      {/* <ToastContainer autoClose={3000} style={{ zIndex: '99 !important' }} /> */}
      <Card key={inputs?._id} variant="outlined" sx={{ background: "#f1f2f6" }}>
        <CardContent>
          <Typography variant="h4" marginBottom={3} color="primary">
            Edit User
          </Typography>
          {isLoading ? (
            <SkeletonLoader />
          ) : (
            <Card>
              <CardContent>
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "right" }}>
                    <Button
                      variant="contained"
                      startIcon={<Iconify icon="ic:sharp-cancel" />}
                      sx={{ mt: 2, mb: 3, mr: 2, py: 1, px: 2 }}
                      style={{
                        backgroundColor: "white",
                        color: "#EFA8B0",
                        borderColor: "#EFA8B0",
                      }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<Iconify icon="ic:sharp-save" />}
                      sx={{ mt: 2, mb: 3, py: 1, px: 2 }}
                      style={{
                        backgroundColor: "white",
                        color: "#74BAB6",
                        borderColor: "#74BAB6",
                      }}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        // onChange={handleInput}
                        value={inputs?.empCode || ""}
                        name="empCode"
                        label="Employee Code"
                        fullWidth
                        required
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        onChange={handleInput}
                        value={inputs?.fullName || ""}
                        name="fullName"
                        label="Full Name"
                        fullWidth
                        required
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        onChange={handleInput}
                        value={inputs?.email || ""}
                        name="email"
                        label="Email"
                        fullWidth
                        required
                        autoComplete="off"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        onChange={handleInput}
                        value={inputs?.phNo || ""}
                        name="phNo"
                        label="Phone"
                        fullWidth
                        required
                        autoComplete="off"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel>Role</InputLabel>
                        <Select
                          labelId="demo-simple-select-required-label"
                          name="role"
                          placeholder="Role"
                          value={inputs?.role || ""}
                          label="Role *"
                          onChange={handleInput}
                        >
                          <MenuItem value="ADMIN">Admin</MenuItem>
                          <MenuItem value="PHOTOGRAPHER">Photographer</MenuItem>
                          <MenuItem value="EDITOR">Editor</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        onChange={(e, newValue) => {
                          handleStatus(newValue);
                        }}
                        value={status ? "Active" : "Inactive"}
                        name="isActive"
                        options={ActiveInactive}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status"
                            required
                            autoComplete="off"
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          value === undefined ||
                          value === "" ||
                          option === value
                        }
                        sx={{
                          marginBottom: "20px",
                          "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
                            {
                              backgroundColor: "#fff",
                            },
                          "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'].Mui-focused":
                            {
                              backgroundColor: "#f6f6f6",
                            },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 1,
                        }}
                      >
                        <Checkbox
                          // checked={selectLocation}
                          checked={selectLocation}
                          onChange={handleCheckboxChange}
                          style={{
                            backgroundColor: "white",
                            color: "#74BAB6",
                            borderColor: "#74BAB6",
                          }}
                        />
                        Location
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      {console.log(selectLocation, "select")}
                      {selectLocation ? (
                        <Button
                          variant="outlined"
                          disabled={!selectLocation}
                          sx={{ paddingTop: 1.8, paddingBottom: 1.8 }}
                          fullWidth
                          onClick={handleOpenMap}
                        >
                          {selectedPlace ? selectedPlace : "Update Location"}
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          disabled={!selectLocation}
                          sx={{ paddingTop: 1.8, paddingBottom: 1.8 }}
                          fullWidth
                          onClick={handleOpenMap}
                        >
                          {selectedPlace ? selectedPlace : "Update Location"}
                        </Button>
                      )}
                    </Grid>

                    <MapModal
                      open={openMap}
                      setAssignedLocation={setAssignedLocation}
                      handleClose={handleCloseMap}
                      selectedPlace={selectedPlace}
                      setSelectedPlace={setSelectedPlace}
                    />
                  </Grid>
                  <Button variant="outlined" onClick={resetPasswordHandler}>
                    Reset Password
                  </Button>
                </Box>
              </CardContent>
            </Card>
          )}
        </CardContent>
      </Card>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6} marginTop={2}>
              <TextField
                name="password"
                label="New password"
                onChange={handleResetPasswordInput}
                value={resetPasswords.password}
              />
            </Grid>
            <Grid item xs={6} marginTop={2}>
              <TextField
                name="cpassword"
                label="Confirm password"
                onChange={handleResetPasswordInput}
                value={resetPasswords.cpassword}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: 2, marginRight: 2 }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            style={{
              backgroundColor: "white",
              color: "#EFA8B0",
              borderColor: "#EFA8B0",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            style={{
              backgroundColor: "white",
              color: "#74BAB6",
              borderColor: "#74BAB6",
            }}
            onClick={updatePassword}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
