import PropTypes from "prop-types";
import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
// @mui
import {
  Card,
  CardHeader,
  Box,
  colors,
  Button,
  Tooltip,
  Typography,
} from "@mui/material";
// components
import { BaseOptionChart } from "../../../components/chart";
import Filterdialog from "src/components/filter-dialog/Filter.Dialog";
import { useContext, useState } from "react";
// import Typography from "src/theme/overrides/Typography";
import IconButton from "src/theme/overrides/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
import Iconify from "src/components/Iconify";
import SalesAnalyticsContext from "src/context/Analytics/Sales.Analytics.Context.Provider";

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppWebsiteVisits({
  title,
  subheader,
  chartLabels,
  moduleName,
  chartData,
  module,
  ...other
}) {
  const { averageSales, totalSales } = useContext(SalesAnalyticsContext);
  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: "28%" } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels.map((label) =>
      label === undefined || label === "undefined" ? "" : label
    ),
    xaxis: {
      type: "category",
      categories: chartLabels.map((label) =>
        label === undefined || label === "undefined" ? "" : label
      ),
      tickPlacement: "on",
      title: {
        text: title === "Sales Package Analytics" ? "Package Amount" : "",
        style: {
          // color:"#EFA8B0",
          fontSize: "1rem",
          fontWeight: "100",
        },
      },
    },
    colors: [title === "Sales Amount Analytics" ? "#74BAB6" : "#EFA8B0"],
    yaxis: {
      title: {
        text: title === "Sales Amount Analytics" ? "Amount (₹)" : "Count",
        style: {
          // color:"#EFA8B0",
          fontSize: "1rem",
          fontWeight: "100",
        },
      },
    },
    // colors:["#EFA8B0"],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y === "number") {
            return `${y.toFixed(0)} `;
          }
          return ""; // Return an empty string or any default value you prefer
        },
      },
    },
  });

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <Card {...other}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CardHeader title={title} subheader={subheader} />
        <Tooltip title="Reset Filter" arrow></Tooltip>

        <Filterdialog
          open={dialogOpen}
          onClose={handleDialogClose}
          module={moduleName}
        />
        <Box onClick={handleDialogOpen} sx={{ marginRight: 2, marginTop: 2 }}>
          <Tooltip title="Filter list" arrow>
            {/* <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton> */}
            <Button sx={{ color: "black", fontSize: "1.5rem" }}>
              <Iconify icon="ic:round-filter-list" />
            </Button>
          </Tooltip>
        </Box>
      </Box>

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={chartData}
          options={chartOptions}
          height={364}
        />
      </Box>
      {title === "Sales Amount Analytics" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            paddingBottom: 2,
          }}
        >
          <Typography variant="subtitle2">Sales Total: {totalSales}</Typography>
          <Typography variant="subtitle2">
            Average Sales: {averageSales}
          </Typography>
        </Box>
      )}
    </Card>
  );
}
