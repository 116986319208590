import React from 'react';
import PropTypes from 'prop-types';
import { Dialog as MuiDialog } from '@mui/material';
import DialogContent from './Filter.Dialog.Content';

function Filterdialog({ open, onClose, module }) {
  return (
    <MuiDialog open={open} onClose={onClose}>
      <DialogContent onClose={onClose} module={module} />
    </MuiDialog>
  );
}

Filterdialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  module: PropTypes.string.isRequired,
};

export default Filterdialog;