// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
import { HelmetProvider } from "react-helmet-async";
// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/chart/BaseOptionChart";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <HelmetProvider>
        <ToastContainer style={{ zIndex: "99 !important" }} />
        <ScrollToTop />
        <BaseOptionChartStyle />
        <Router />
      </HelmetProvider>
    </ThemeProvider>
  );
}
