import { getHospitals } from "src/service/hospital.service";

const { createContext, useState } = require("react");

const HospitalListContext = createContext();
export const HospitalListContextProvider = ({ children }) => {
  const [hospitalData, setHospitalData] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(0);
  const [fetchedPages, setFetchedPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (filterName, offset = 0, statusFilter, isDeleted) => {
    setIsLoading(true);
    const { data } = await getHospitals(
      filterName,
      rowsPerPage,
      offset,
      statusFilter
    );
    setIsLoading(false);
    if (data?.data?.pagination?.offset === "0") {
      console.log("inside offset 0");
      setHospitalData(data?.data?.items);
    } else if (isDeleted) {
      console.log("inside else if 2nd ");
      setHospitalData((prevData) => {
        const newData = [...prevData];
        newData.splice(page * rowsPerPage + selectedIndex, 1);
        return newData;
      });
    } else {
      console.log("inside else");
      setHospitalData((prevData) => [...prevData, ...data?.data?.items]);
    }

    console.log(data?.data?.items);
    setPagination(data?.data?.pagination);
  };
  return (
    <HospitalListContext.Provider
      value={{
        hospitalData,
        setHospitalData,
        fetchData,
        pagination,
        setPagination,
        rowsPerPage,
        setRowsPerPage,
        fetchedPages,
        page,
        setPage,
        setFetchedPages,
        status,
        setStatus,
        filter,
        setFilter,
        search,
        setSearch,
        isLoading,
        setSelectedIndex,
      }}
    >
      {children}
    </HospitalListContext.Provider>
  );
};
export default HospitalListContext;
