const Joi = require('joi');

const Schema = Joi.object({
  client: Joi.string()
    .messages({
      'any.required': `Client is a required field.`,
      'string.empty': `Client must contain value.`,
    })
    .required(),
  shoot: Joi.string()
    .messages({
      'any.required': `Shoots is a required field.`,
      'string.empty': `Shoots must contain value.`,
    })
    .required(),
  description: Joi.string()
    .messages({
      'any.required': `Description is a required field.`,
      'string.empty': `Description must contain value.`,
    })
    .required(),
}).unknown(true);

export default Schema;