const Joi = require("joi");

const Schema = Joi.object({
  fullName: Joi.string()
    .messages({
      "any.required": `Full Name is a required field.`,
      "string.empty": `Full Name must contain value.`,
    })
    .required(),
  pass: Joi.string()
    .min(6)
    .required()
    .messages({
      "any.required": `Password is a required field.`,
      "string.empty": `Password must contain value.`,
      "string.min": "Password must be at least {#limit} characters long.",
    })
    .required(),
  // email: Joi.string().messages({
  //   "any.required": `Email Id is a required field.`,
  //   "string.empty": `Email Id must contain value.`,
  // }),
  phNo: Joi.string()
    .regex(/^[\d]{10}$/)
    .min(10)
    .max(10)
    .messages({
      "any.required": `Phone Number is a required field.`,
      "string.min": "Number must be exactly 10 digits.",
      "string.max": "Number must be exactly 10 digits.",
      "string.empty": `Phone Number must contain value.`,
      "string.pattern.base": "Phone Number must be exactly 10 digits.",
    })
    .required(),
  empCode: Joi.string()
    .messages({
      "any.required": `Employee Code is a required field.`,
      "string.empty": `Employee Code must contain value.`,
    })
    .required(),
  role: Joi.string().messages({
    "any.required": `Role is a required field.`,
    "string.empty": `Role must contain value.`,
  }),
}).unknown(true);
export default Schema;
