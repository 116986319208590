import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AttendanceListContext from "src/context/Attendance/Attendance.Context.Provider";
import { getUsers } from "src/service/user.service";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { toast } from "react-toastify";

export default function AttendanceFilterDialogContent({ onClose }) {
  const {
    attendanceData,
    setAttendanceData,
    fetchData,
    pagination,
    setPagination,
    rowsPerPage,
    setRowsPerPage,
    fetchedPages,
    page,
    setPage,
    setFetchedPages,
    status,
    setStatus,
    filter,
    setFilter,
    search,
    setSearch,
    isLoading,
    empCode,
    setEmpCode,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
  } = useContext(AttendanceListContext);
  const [userData, setUserData] = useState([]);
  const [empCodeData, setEmpCodeData] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedValue, setSelectedValue] = React.useState("");

  const getAllUsers = async () => {
    const { data } = await getUsers("", "", -1, 0);
    setUserData(data?.data?.items?.filter((item) => item?.role !== "ADMIN"));
    // setUserData(data?.data?.items);
    console.log(userData, "bara");
  };

  const handleApply = () => {
    setEmpCode(empCodeData);
    if ((startDate && endDate) === null || undefined || "") {
      setFromDate("");
      setToDate("");
    } else {
      console.log(fromDate, "date");
      console.log(startDate, "date");
      console.log(endDate, "date");
      console.log(toDate, "date");

      setFromDate(startDate.valueOf());
      setToDate(endDate.valueOf());
    }
    toast("Filter applied successfully", {
      position: "top-right",
      autoClose: 3579,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      type: "success",
    });

    onClose();
  };
  const handleClose = () => {
    setEmpCode("");
    setFromDate("");
    setToDate("");
    onClose();
  };

  useEffect(() => {
    getAllUsers();
  }, []);
  return (
    <>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Autocomplete
            id="empCodeData"
            onChange={(e, newValue) => {
              if (newValue) {
                setEmpCodeData(newValue?.empCode);
                setSelectedValue(newValue);
              } else {
                setEmpCodeData("");
                setSelectedValue("");
              }
            }}
            defaultValue={""}
            value={selectedValue || ""}
            name="empCode"
            options={userData || []}
            renderInput={(params) => (
              <TextField {...params} label="User List" required />
            )}
            getOptionLabel={(option) => option.fullName || ""}
            isOptionEqualToValue={(option, value) =>
              value === undefined ||
              value === "" ||
              option?.empCode === value?.empCode
            }
            getOptionSelected={(option, value) =>
              option?.empCode === value?.empCode
            }
            sx={{
              "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
                {
                  backgroundColor: "#fff",
                },
              "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'].Mui-focused":
                {
                  backgroundColor: "#f6f6f6",
                },
            }}
          />
        </Grid>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={startDate}
                name="startDate"
                format="DD/MM/YYYY"
                onChange={(newStartDate) => {
                  if (newStartDate)
                    return setStartDate(dayjs(newStartDate).startOf("day"));
                  return setStartDate(null);
                }}
                //   sx={{ width: "100% !important" }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                value={endDate}
                name="startDate"
                format="DD/MM/YYYY"
                onChange={(newEndDate) => {
                  if (newEndDate)
                    return setEndDate(dayjs(newEndDate).endOf("day"));
                  return setEndDate(null);
                }}
                //   sx={{ width: "100% !important" }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
      <Box position="absolute" right="25px" bottom="25px">
        <Button
          onClick={handleClose}
          variant="outlined"
          style={{
            backgroundColor: "white",
            color: "#EFA8B0",
            borderColor: "#EFA8B0",
            marginRight: "4px",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          style={{
            backgroundColor: "white",
            color: "#74BAB6",
            borderColor: "#74BAB6",
          }}
          onClick={handleApply}
        >
          Apply
        </Button>
      </Box>
    </>
  );
}
