import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Alert, Box, Grid, TextField } from "@mui/material";
import { createHospital } from "src/service/hospital.service";
import Schema from "src/components/Joi.validation/Hospital.create.schema.validation";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function HospitalCreateDialog({
  dialogOpen,
  handleDialogClose,
  fetchData,
  setPage,
  setFetchedPages,
}) {
  const [validationErrors, setValidationErrors] = React.useState({});
  const [input, setInput] = React.useState({});

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setInput({
      name: "",
      address: "",
      contactNumber: "",
    });
  };

  const dataSendingToBackend = {
    ...input,
  };

  const addNewHospital = async () => {
    const { error, value } = Schema.validate(dataSendingToBackend);
    if (error) {
      const errors = error.details.reduce((acc, curr) => {
        return { ...acc, [curr.context.key]: curr.message };
      }, {});
      setValidationErrors(errors);
      return false;
    } else {
      setValidationErrors({});
      const { data } = await createHospital(dataSendingToBackend);
      if (data?.code === 200) {
        toast.success("New hospital created!");
        setPage(0);
        setFetchedPages(1);
        fetchData();
        handleDialogClose();
        resetForm();
        console.log(data);
      }
    }
  };
  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
          {"Add New Hospital"}
        </DialogTitle>
        <DialogContent>
          <Alert severity="info">
            Please fill all the details of new hospital and click the save
            button bellow.
          </Alert>
          <Box sx={{ marginTop: 2 }} component="form">
            <Grid container spacing={2}>
              <Grid
                sx={{ display: "flex", justifyContent: "center" }}
                item
                xs={6}
              >
                <TextField
                  error={Boolean(validationErrors.name)}
                  helperText={validationErrors.name}
                  id="standard-error-helper-text"
                  name="name"
                  value={input.name}
                  onChange={handleInput}
                  label="Name"
                  required
                  fullWidth
                />
              </Grid>
              <Grid
                sx={{ display: "flex", justifyContent: "center" }}
                item
                xs={6}
              >
                <TextField
                  error={Boolean(validationErrors.address)}
                  helperText={validationErrors.address}
                  id="standard-error-helper-text"
                  name="address"
                  value={input.address || ""}
                  onChange={handleInput}
                  label="Address"
                  required
                  fullWidth
                />
              </Grid>
              <Grid
                sx={{ display: "flex", justifyContent: "center" }}
                item
                xs={6}
              >
                <TextField
                  error={Boolean(validationErrors.contactNumber)}
                  helperText={validationErrors.contactNumber}
                  id="standard-error-helper-text"
                  name="contactNumber"
                  value={input.contactNumber}
                  onChange={handleInput}
                  label="Contact Number"
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="right" marginTop={6} gap={3}>
              <Button
                variant="outlined"
                style={{
                  backgroundColor: "white",
                  color: "#EFA8B0",
                  borderColor: "#EFA8B0",
                }}
                onClick={() => {
                  handleDialogClose();
                  resetForm();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                style={{
                  backgroundColor: "white",
                  color: "#74BAB6",
                  borderColor: "#74BAB6",
                }}
                onClick={addNewHospital}
              >
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
