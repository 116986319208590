import { getTickets } from "src/service/ticket.service";

const { createContext, useState } = require("react");

const TicketListContext = createContext();
export const TicketListContextProvider = ({ children }) => {
  const [ticketData, setTicketData] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(0);
  const [fetchedPages, setFetchedPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (filterName, offset = 0, filterStatus = status) => {
    setIsLoading(true);
    const { data } = await getTickets(
      filterName,
      rowsPerPage,
      offset,
      filterStatus
    );
    setIsLoading(false);
    if (data?.data?.pagination?.offset === "0")
      setTicketData(data?.data?.items);
    else {
      setTicketData((preValue) => {
        console.log("trying to get ticket array", [
          ...preValue,
          ...data?.data?.items,
        ]);
        return [...preValue, ...data?.data?.items];
      });
    }
    console.log(data?.data?.items, "ticket data");

    setPagination(data?.data?.pagination);
  };

  return (
    <TicketListContext.Provider
      value={{
        ticketData,
        setTicketData,
        fetchData,
        pagination,
        setPagination,
        rowsPerPage,
        setRowsPerPage,
        fetchedPages,
        page,
        setPage,
        setFetchedPages,
        status,
        setStatus,
        filter,
        setFilter,
        search,
        setSearch,
        isLoading,
      }}
    >
      {children}
    </TicketListContext.Provider>
  );
};
export default TicketListContext;
