import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Iconify from "../../components/Iconify";

import Stack from "@mui/material/Stack";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TicketListContext from "src/context/Ticket/Ticket.Context.Provider";
import {
  getTickets,
  updateResolutionStatus,
  updateTicketStatus,
  viewTicket,
} from "src/service/ticket.service";

import SkeletonLoader from "src/components/skeleton/Skeleton.Loader";

export default function TicketView() {
  const { ticketId } = useParams();
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [detailsValue, setDetailsValue] = useState({});

  const navigate = useNavigate();

  const handleInput = (e) => {
    const { name, value } = e.target;
    setDetailsValue((preValue) => ({ ...preValue, [name]: value }));
  };

  const fetchTicketDetails = async () => {
    setIsLoading(true);
    const { data } = await viewTicket(ticketId);
    setInputs(data?.data);
    setDetailsValue(data?.data);
    setIsLoading(false);
  };

  const updateStatus = async () => {
    const { data } = await updateTicketStatus(ticketId, {
      status: detailsValue.status,
    });
    console.log(data);
    fetchTicketDetails();
  };

  const updateResolution = async () => {
    const { data } = await updateResolutionStatus(ticketId, {
      resolution: detailsValue.resolution,
    });
    console.log(data, "datas");
    fetchTicketDetails();
  };

  useEffect(() => {
    fetchTicketDetails();
  }, []);
  return (
    <>
      <Helmet>
        <title>
          {" "}
          {inputs?.ticketNo?.split(" ")[0] || "Ticket"}'s detail | Camories{" "}
        </title>
      </Helmet>
      {/* {isLoading && <Ticketloadingskeleton />} */}

      {isLoading === true ? (
        <SkeletonLoader />
      ) : (
        <Card
          key={inputs?._id}
          variant="outlined"
          sx={{ background: "#f1f2f6" }}
        >
          {console.log(inputs, "gudum")}
          <CardContent>
            <Typography variant="h4" marginBottom={3} color="primary">
              Ticket Details{console.log(inputs)}
            </Typography>
            <Card>
              <CardContent>
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "right" }}>
                    <Button
                      variant="contained"
                      sx={{ mt: 2, mb: 3, mr: 2, py: 1, px: 2 }}
                      onClick={() => navigate(-1)}
                      style={{
                        backgroundColor: "white",
                        color: "#EFA8B0",
                        borderColor: "#EFA8B0",
                      }}
                    >
                      <ArrowBackIcon />
                      Back
                    </Button>
                  </Box>

                  <Grid container spacing={2}>
                    {inputs?.client && (
                      <Grid item xs={6}>
                        <TextField
                          value={inputs?.client?.name || ""}
                          name="name"
                          label="Client's Name"
                          fullWidth
                        />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.ticketNo || ""}
                        name="ticketNo"
                        label="Ticket No."
                        fullWidth
                      />
                    </Grid>
                    {inputs?.client && (
                      <Grid item xs={6}>
                        <TextField
                          value={inputs?.client?.uhid || ""}
                          name="uhid"
                          label="UHID"
                          fullWidth
                        />
                      </Grid>
                    )}
                    {inputs?.client && (
                      <Grid item xs={6}>
                        <TextField
                          value={inputs?.client?.email || ""}
                          name="email"
                          label="E-mail ID"
                          fullWidth
                        />
                      </Grid>
                    )}

                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.description || ""}
                        name="description"
                        label="Description"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.shoot?.hospital?.name || ""}
                        name="hospitalName"
                        label="Hospital Name"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                          name="status"
                          label="Status"
                          disabled={
                            inputs?.status === "In Progress" ||
                            inputs?.status === "Completed"
                          }
                          // defaultValue={'v'}
                          value={detailsValue.status || ""}
                          onChange={handleInput}
                          fullWidth
                        >
                          <MenuItem value={detailsValue.status}>
                            {detailsValue.status}
                          </MenuItem>
                          {inputs?.status === "In Progress" ? null : (
                            <MenuItem value={"In Progress"}>
                              In Progress
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "right", mt: 2 }}>
                  <Button
                    variant="outlined"
                    style={
                      inputs?.status === "In Progress" ||
                      inputs?.status === "Completed"
                        ? {
                            backgroundColor: "white",
                            color: "gray",
                            borderColor: "gray",
                          }
                        : {
                            backgroundColor: "white",
                            color: "#74BAB6",
                            borderColor: "#74BAB6",
                          }
                    }
                    // sx={{ cursor: inputs[0]?.status === 'In Progress' ? 'not-allowed' : 'pointer' }}

                    onClick={updateStatus}
                    disabled={
                      inputs?.status === "In Progress" ||
                      inputs?.status === "Completed"
                    }
                  >
                    Apply Change
                  </Button>
                </Box>
              </CardContent>
            </Card>
            {(inputs?.status === "In Progress" ||
              inputs?.status === "Completed") && (
              <Card sx={{ marginTop: 1 }}>
                <CardContent>
                  <TextField
                    name="resolution"
                    defaultValue={inputs?.resolution}
                    label="Resolution"
                    value={detailsValue.resolution}
                    onChange={handleInput}
                    fullWidth
                    disabled={inputs?.status === "Completed"}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      mt: 3,
                      mb: 1,
                    }}
                  >
                    <Tooltip title="Update Resolution">
                      <Button
                        variant="outlined"
                        style={
                          inputs?.status === "Completed"
                            ? {
                                backgroundColor: "white",
                                color: "gray",
                                borderColor: "gray",
                              }
                            : {
                                backgroundColor: "white",
                                color: "#74BAB6",
                                borderColor: "#74BAB6",
                              }
                        }
                        disabled={inputs?.status === "Completed"}
                        onClick={updateResolution}
                      >
                        {inputs?.status === "Completed" ? "Updated" : "Update"}
                      </Button>
                    </Tooltip>
                  </Box>
                </CardContent>
              </Card>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
}
// 1713209399000
// 1713274199000
