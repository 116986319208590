import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Paper,
  Popover,
  MenuItem,
  IconButton,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { sentenceCase } from "change-case";
import Slide from "@mui/material/Slide";
import Label from "src/components/Label";
import Scrollbar from "src/components/Scrollbar";
// import Iconify from 'src/components/Iconify';
import { CURRENT_USER } from "src/utils/constants";
import ClientsContext from "src/context/Client/Clients.Context.Provider";
import { UserListHead } from "src/sections/@dashboard/user";
// import HospitalCreateDialog from '../hospital/Hospital.Create.Dialog.Content';
import HospitalListToolbar from "src/sections/@dashboard/hospital/HospitalListToolbar";
import ClientListToolbar from "src/sections/@dashboard/clients/ClientListToolbar";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { deleteClient } from "src/service/clients.service";
import { toast } from "react-toastify";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis?.map((el) => el[0]);
}
const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

function GradientCircularProgress() {
  return (
    <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#74BAB6" />
            <stop offset="100%" stopColor="#EFA8B0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
      />
    </React.Fragment>
  );
}
export default function ClientsList() {
  const loginData =
    JSON.parse(localStorage.getItem(CURRENT_USER)) ||
    JSON.parse(sessionStorage.getItem(CURRENT_USER));

  const TABLE_HEAD = [
    { id: "uhid", label: "UHID", alignRight: false },
    { id: "name", label: "Client Name", alignRight: false },
    { id: "phone no", label: "Phone Number", alignRight: false },
    { id: "email", label: "Email", alignRight: false },
    { id: "delete", label: "Delete", alignRight: false },
  ];

  const {
    clientData,
    setClientData,
    fetchData,
    pagination,
    rowsPerPage,
    fetchedPages,
    page,
    setPage,
    setFetchedPages,
    search,
    setSearch,
    isLoading,
  } = useContext(ClientsContext);

  const [open, setOpen] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedHospitalId, setSelectedUserId] = useState("");
  const [enable, setEnable] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleDelete = async (id) => {
    console.log("Deleting hospital with ID:", id);
    const { data } = await deleteClient(id);
    if (data?.code === 200) {
      toast.success("Client Deleted Successfully");
      fetchData();
      setPage(0);
      setFetchedPages(1);
      console.log("Deleting shoot with ID:", id);
      handleDialogClose();
    } else {
      toast.error("There is an error in client deletion");
    }
  };

  const handleDialogOpen = (row) => {
    setSelectedUserId(row._id);
    console.log(row._id);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage, "newPage");
    setPage(newPage);
    if (newPage > fetchedPages - 1) {
      setFetchedPages((value) => value + 1);
    }
    console.log(fetchedPages, "fetchedPages");
  };

  const handleSearch = (event) => {
    setPage(0);
    setFetchedPages(1);
    setSearch(event.target.value);
  };

  const handleClickRow = (row) => {
    console.log(row?._id);
    navigate(`/clients/${row?._id}`);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - clientData?.length) : 0;

  const filteredUsers = applySortFilter(
    clientData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    getComparator(order, orderBy)
  );

  const isNotFound = !filteredUsers?.length && !!search;

  useEffect(() => {
    let timer;
    console.count("test surveyList useEffect");

    const fetchDataWithDebounce = () => {
      fetchData(search, page * rowsPerPage);
    };

    const debounce = () => {
      timer = setTimeout(fetchDataWithDebounce, 800);
    };

    const noDebounce = () => {
      if (fetchedPages === 1 || page >= fetchedPages - 1) {
        fetchDataWithDebounce();
      }
    };

    if (search) debounce();
    else noDebounce();

    return () => {
      clearTimeout(timer);
    };
  }, [search, fetchedPages]);
  return (
    <>
      <Helmet>
        <title> Clients | Camories </title>
      </Helmet>

      <Container maxWidth={false}>
        <Card>
          <ClientListToolbar
            search={search}
            onSearch={handleSearch}
            value={"client"}
          />

          <Scrollbar>
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "300px",
                }}
              >
                <GradientCircularProgress />
              </Box>
            ) : clientData?.length > 0 || isNotFound ? (
              <TableContainer sx={{ minWidth: 800, overflow: "hidden" }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    onRequestSort={handleRequestSort}
                  />

                  <TableBody>
                    {filteredUsers?.map((row) => {
                      const { _id, name, phoneNo, uhid, email } = row;
                      console.log(filteredUsers, "bal");

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                handleClickRow(row);
                              }}
                            >
                              {uhid}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleClickRow(row);
                                }}
                              >
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{phoneNo}</TableCell>
                          {/* <TableCell align="left">{phoneNo}</TableCell> */}
                          <TableCell align="left">{email}</TableCell>

                          <TableCell align="left">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => handleDialogOpen(row)}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow sx={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{search}&quot;</strong>.
                              <br /> Try checking for typos or using complete
                              words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4">Data not available.</Typography>
              </Box>
            )}
          </Scrollbar>
          {!isLoading && filteredUsers?.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={pagination?.total || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>

      {/* <Popover
      open={Boolean(open)}
      anchorEl={open}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem onClick={() => handleEdit(selectedHospitalId)}>Edit</MenuItem>

      <MenuItem sx={{ color: 'error.main' }}>
        <>
          <Grid
            item
            xs={10}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'black',
            }}
            onClick={() => handleEnable(selectedHospitalId)}
          >
            {enable ? 'Disable' : 'Enable'}
          </Grid>
        </>
      </MenuItem>
      <MenuItem sx={{ color: 'error.main' }} onClick={handleOpenDialogDelete}>
        Delete
      </MenuItem>
    </Popover> */}
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Client?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            variant="outlined"
            style={{
              backgroundColor: "white",
              color: "#EFA8B0",
              borderColor: "#EFA8B0",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            style={{
              backgroundColor: "white",
              color: "#74BAB6",
              borderColor: "#74BAB6",
            }}
            onClick={() => handleDelete(selectedHospitalId)}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
