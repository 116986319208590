import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ShootsContext from "src/context/Shoots/Shoots.Context.Provider";
import Hospital from "src/pages/hospital/Hospital";
import { getHospitals } from "src/service/hospital.service";

export default function ShootFilterDialogContent({ onClose }) {
  const {
    setPage,
    setFetchedPages,
    setStatus,
    setFromDate,
    setToDate,
    setHospital,
    hospital,
  } = useContext(ShootsContext);
  const filteredStatusList = [
    "Shoot Created",
    "Shoot Pending",
    "Shoot Done",
    "Shoot Rejected",
  ];
  const [activeStatus, setActiveStatus] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [hospitalId, setHospitalId] = useState("");
  const [hospitalList, setHospitalList] = useState([]);

  const handleApply = () => {
    if ((startDate && endDate) === null || undefined || "") {
      setFromDate("");
      setToDate("");
    } else {
      setFromDate(startDate.valueOf());
      setToDate(endDate.valueOf());
    }
    if (activeStatus === "Shoot Created") {
      setStatus("Shoot Created");
    } else if (activeStatus === "Shoot Pending") {
      setStatus("Shoot Pending");
    } else if (activeStatus === "Shoot Done") {
      setStatus("Shoot Done");
    } else if (activeStatus === "Shoot Rejected") {
      setStatus("Shoot Rejected");
    } else {
      setStatus("");
    }
    if (hospitalId) {
      setHospital(hospitalId?._id);
      console.log(hospitalId?._id, "hospitalid when click");
      console.log(hospital, "hospitalid when click");
    }
    setPage(0);
    setFetchedPages(1);
    toast("Filter applied successfully", {
      position: "top-right",
      autoClose: 3579,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      type: "success",
    });
    onClose();
  };
  const getHospitalsList = async () => {
    const { data } = await getHospitals("", -1, 0, "");
    setHospitalList(data?.data?.items);
  };
  useEffect(() => {
    getHospitalsList();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Grid item xs={12} marginBottom={2}>
          <Autocomplete
            onChange={(e, newValue) => setActiveStatus(newValue)}
            fullWidth
            value={activeStatus || ""}
            options={filteredStatusList || []}
            renderInput={(params) => (
              <TextField {...params} label="Shoot Status" />
            )}
            getOptionLabel={(options) => options}
          />
        </Grid>
        <Grid item xs={12} marginBottom={2}>
          <Autocomplete
            name="hospital"
            onChange={(e, newValue) => setHospitalId(newValue)}
            fullWidth
            value={hospitalId || ""}
            options={hospitalList || []}
            renderInput={(params) => (
              <TextField {...params} label="Hospitals" required />
            )}
            getOptionLabel={(options) =>
              options?._id ? ` ${options?.name}` : ""
            }
            isOptionEqualToValue={(option, value) =>
              value === undefined || value === "" || option?._id === value?._id
            }
            getOptionSelected={(option, value) => option?._id === value?._id}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={startDate}
                name="startDate"
                format="DD/MM/YYYY"
                onChange={(newStartDate) => {
                  if (newStartDate)
                    return setStartDate(dayjs(newStartDate).startOf("day"));
                  return setStartDate(null);
                }}
                //   sx={{ width: "100% !important" }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                value={endDate}
                name="startDate"
                format="DD/MM/YYYY"
                onChange={(newEndDate) => {
                  if (newEndDate)
                    return setEndDate(dayjs(newEndDate).endOf("day"));
                  return setEndDate(null);
                }}
                //   sx={{ width: "100% !important" }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
      <Box position="absolute" right="25px" bottom="25px">
        <Button
          onClick={onClose}
          variant="outlined"
          style={{
            backgroundColor: "white",
            color: "#EFA8B0",
            borderColor: "#EFA8B0",
            marginRight: "4px",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          style={{
            backgroundColor: "white",
            color: "#74BAB6",
            borderColor: "#74BAB6",
          }}
          onClick={handleApply}
        >
          Apply
        </Button>
      </Box>
    </>
  );
}
