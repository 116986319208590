import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Paper,
  Popover,
  MenuItem,
  IconButton,
  Box,
  CircularProgress,
  Dialog,
} from "@mui/material";
import { sentenceCase } from "change-case";
import Slide from "@mui/material/Slide";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Label from "src/components/Label";
import Scrollbar from "src/components/Scrollbar";
import Iconify from "src/components/Iconify";
import { CURRENT_USER } from "src/utils/constants";
import HospitalListContext from "src/context/Hospital/Hospital.Context.Provider";
import { UserListHead } from "src/sections/@dashboard/user";
// import HospitalCreateDialog from './Hospital.Create.Dialog.Content';
import {
  deleteHospital,
  isEnabledHospital,
} from "src/service/hospital.service";
import HospitalListToolbar from "src/sections/@dashboard/hospital/HospitalListToolbar";
import TicketCreateDialogContent from "./Ticket.Create.Dialog.Content";
import TicketListContext from "src/context/Ticket/Ticket.Context.Provider";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import TicketListToolbar from "src/sections/@dashboard/ticket/TicketListToolbar";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis?.map((el) => el[0]);
}
const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

function GradientCircularProgress() {
  return (
    <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#74BAB6" />
            <stop offset="100%" stopColor="#EFA8B0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
      />
    </React.Fragment>
  );
}

export default function Ticket() {
  const loginData =
    JSON.parse(localStorage.getItem(CURRENT_USER)) ||
    JSON.parse(sessionStorage.getItem(CURRENT_USER));

  const TABLE_HEAD = [
    { id: "ticketNo", label: "Ticket No.", alignRight: false },
    { id: "name", label: "Client Name.", alignRight: false },
    { id: "description", label: "Description", alignRight: false },
    { id: "resolution", label: "Resolution", alignRight: false },
    { id: "status", label: "Status", alignRight: false },
  ];

  const {
    ticketData,
    setTicketData,
    fetchData,
    pagination,
    rowsPerPage,
    fetchedPages,
    page,
    setPage,
    setFetchedPages,
    search,
    setSearch,
    isLoading,
    status,
  } = useContext(TicketListContext);

  const [open, setOpen] = useState(null);

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("name");

  const [dialogOpen, setDialogOpen] = useState(false);

  const [selectedHospitalId, setSelectedUserId] = useState("");

  const [enable, setEnable] = useState();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialogDelete = () => {
    setIsDialogOpen(true);
    console.log("testing dialog open");
  };

  const handleCloseDialogDelete = () => {
    setIsDialogOpen(false);
    handleCloseMenu();
    console.log("testing dialog delete");
  };

  // const handleEdit = (selectedHospitalId) => {
  //   console.log(selectedHospitalId);
  //   navigate(`/hospitals/${selectedHospitalId}/edit`);
  // };

  // const handleEnable = async (selectedHospitalId) => {
  //   setEnable(!enable);
  //   await isEnabledHospital(selectedHospitalId, { enable: !enable });
  //   handleCloseMenu();
  //   fetchData();
  // };

  // const handleDelete = async (selectedHospitalId) => {
  //   await deleteHospital(selectedHospitalId);
  //   handleCloseMenu();
  //   fetchData();
  //   handleCloseDialogDelete();
  // };

  const handleDialogOpen = () => {
    setDialogOpen(true);
    console.log("testing dialog open new user");
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const navigate = useNavigate();

  // const handleOpenMenu = (event, row) => {
  //   setOpen(event.currentTarget);
  //   setSelectedUserId(row?._id);
  //   setEnable(row?.isActive);
  // };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage, "newPage");
    setPage(newPage);
    if (newPage > fetchedPages - 1) {
      setFetchedPages((value) => value + 1);
    }
    console.log(fetchedPages, "fetchedPages");
  };

  const handleSearch = (event) => {
    setPage(0);
    setFetchedPages(1);
    setSearch(event.target.value);
  };

  const handleClickRow = (row) => {
    console.log(row?._id);
    navigate(`/tickets/view/${row?._id}`);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ticketData?.length) : 0;

  const filteredUsers = applySortFilter(
    ticketData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    getComparator(order, orderBy)
  );

  const isNotFound = !filteredUsers?.length && !!search;

  useEffect(() => {
    let timer;
    console.count("test surveyList useEffect");

    const fetchDataWithDebounce = () => {
      fetchData(search, page * rowsPerPage, status);
    };

    const debounce = () => {
      timer = setTimeout(fetchDataWithDebounce, 800);
    };

    const noDebounce = () => {
      if (fetchedPages === 1 || page >= fetchedPages - 1) {
        fetchDataWithDebounce();
      }
    };

    if (search) debounce();
    else noDebounce();

    return () => {
      clearTimeout(timer);
    };
  }, [search, fetchedPages, status]);

  return (
    <>
      <Helmet>
        <title> Ticket | Camories </title>
      </Helmet>
      <Container maxWidth={false}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h4" gutterBottom color="primary">
            {/* User */}
          </Typography>
          {loginData?.role === "ADMIN" && (
            <Button
              variant="contained"
              style={{ backgroundColor: "#74BAB6", color: "white" }}
              startIcon={<ConfirmationNumberIcon />}
              onClick={handleDialogOpen}
            >
              Create Ticket
            </Button>
          )}
          <TicketCreateDialogContent
            dialogOpen={dialogOpen}
            handleDialogClose={handleDialogClose}
            setHospitalData={setTicketData}
            fetchData={fetchData}
          />
        </Stack>

        <Card>
          <TicketListToolbar
            search={search}
            onSearch={handleSearch}
            fetchData={fetchData}
          />

          <Scrollbar>
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "300px",
                }}
              >
                <GradientCircularProgress />
              </Box>
            ) : ticketData?.length > 0 || isNotFound ? (
              <TableContainer sx={{ minWidth: 800, overflow: "hidden" }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    onRequestSort={handleRequestSort}
                  />

                  <TableBody>
                    {filteredUsers?.map((row) => {
                      // const { _id,name, address, contactNumber, isActive } = row;
                      console.log(filteredUsers, "bal");

                      return (
                        <TableRow
                          hover
                          key={row?._id}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                handleClickRow(row);
                              }}
                            >
                              {row?.ticketNo || "NA"}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            {row?.client?.name || "NA"}
                          </TableCell>

                          <TableCell align="left">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleClickRow(row);
                                }}
                              >
                                {row?.description || "NA"}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {row?.resolution || "NA"}
                          </TableCell>
                          <TableCell align="left">
                            {row?.status || "Not Specified"}
                          </TableCell>
                          {/* <TableCell align="center">
                            <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow sx={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{search}&quot;</strong>.
                              <br /> Try checking for typos or using complete
                              words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4">Data not available.</Typography>
              </Box>
            )}
          </Scrollbar>
          {!isLoading && filteredUsers?.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={pagination?.total || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>

      {/* <Popover
      open={Boolean(open)}
      anchorEl={open}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem onClick={() => handleEdit(selectedHospitalId)}>Edit</MenuItem>

      <MenuItem sx={{ color: 'error.main' }}>
        <>
          <Grid
            item
            xs={10}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'black',
            }}
            onClick={() => handleEnable(selectedHospitalId)}
          >
            {enable ? 'Disable' : 'Enable'}
          </Grid>
        </>
      </MenuItem>
      <MenuItem sx={{ color: 'error.main' }} onClick={handleOpenDialogDelete}>
        Delete
      </MenuItem>
    </Popover> */}
      {/* <Dialog open={isDialogOpen} onClose={handleCloseDialogDelete} TransitionComponent={Transition}>
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete this Hospital?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialogDelete} variant="outlined" style={{ backgroundColor: 'white', color: '#EFA8B0', borderColor:'#EFA8B0' }}>
          Cancel
        </Button>
        <Button variant="outlined" style={{ backgroundColor: 'white', color: '#74BAB6', borderColor:'#74BAB6' }} onClick={() => handleDelete(selectedHospitalId)}>
          Delete
        </Button>
      </DialogActions>
    </Dialog> */}
    </>
  );
}
