import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { getAllClients } from "src/service/clients.service";
import { createTicket } from "src/service/ticket.service";
import { getShoots } from "src/service/shoots.service";
import { toast } from "react-toastify";
import Schema from "src/components/Joi.validation/Ticket.create.Schema";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function TicketCreateDialogContent({
  dialogOpen,
  handleDialogClose,
  fetchData,
}) {
  const [validationErrors, setValidationErrors] = React.useState({});
  const [clientDescription, setClientDescription] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [shootId, setShootId] = React.useState("");
  const [shootOptions, setShootOptions] = React.useState([]);

  const [input, setInput] = React.useState({});

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  const dataSendingToBackend = {
    ...input,
    client: selectedValue._id,
    shoot: shootId._id,
  };

  const getClientDetails = async () => {
    const { data } = await getAllClients("", -1, 0);
    console.log(data?.data?.items, "get useres details in create ticket");
    setClientDescription(data?.data?.items);
  };
  const getShootDetails = async () => {
    const { data } = await getShoots("", "", "", -1, 0, "");
    console.log(data);
    setShootOptions(data?.data?.items);
  };

  const resetForm = () => {
    setInput({
      description: "",
    });
  };

  const addNewTicket = async () => {
    const { error, value } = Schema.validate(dataSendingToBackend);

    console.log(error);
    if (error) {
      error?.details?.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      const { data } = await createTicket(dataSendingToBackend);
      if (data?.code === 200) {
        fetchData("", 0);
        toast.success("New Ticket has been raised");
        handleDialogClose();
        resetForm();
        setSelectedValue("");
        console.log(data);
      }
    }
  };

  React.useEffect(() => {
    getClientDetails();
    getShootDetails();
  }, []);

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
          {"Apply for New Ticket"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ marginTop: 2 }} component="form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSelectedValue(newValue);
                    } else {
                      setSelectedValue("");
                    }
                  }}
                  defaultValue={""}
                  value={selectedValue || ""}
                  name="client"
                  options={clientDescription || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client"
                      name="client"
                      value={input.client}
                      onChange={handleInput}
                      required
                    />
                  )}
                  getOptionLabel={(option) => option.name || ""}
                  isOptionEqualToValue={(option, value) =>
                    value === undefined ||
                    value === "" ||
                    option?._id === value?._id
                  }
                  getOptionSelected={(option, value) =>
                    option?._id === value?._id
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  name="shoot"
                  onChange={(e, newValue) => setShootId(newValue)}
                  fullWidth
                  value={shootId || ""}
                  options={shootOptions || []}
                  renderInput={(params) => (
                    <TextField {...params} label="Shoots" required />
                  )}
                  getOptionLabel={(options) =>
                    options?._id
                      ? `${options?.shootNo} (${options?.client?.name})`
                      : ""
                  }
                  isOptionEqualToValue={(option, value) =>
                    value === undefined ||
                    value === "" ||
                    option?._id === value?._id
                  }
                  getOptionSelected={(option, value) =>
                    option?._id === value?._id
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  error={Boolean(validationErrors.contactNumber)}
                  helperText={validationErrors.contactNumber}
                  id="standard-error-helper-text"
                  name="description"
                  value={input.description}
                  onChange={handleInput}
                  label="Description"
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="right" marginTop={6} gap={3}>
              <Button
                variant="outlined"
                style={{
                  backgroundColor: "white",
                  color: "#EFA8B0",
                  borderColor: "#EFA8B0",
                }}
                onClick={() => {
                  handleDialogClose();
                  resetForm();
                  setSelectedValue("");
                  setShootId("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                style={{
                  backgroundColor: "white",
                  color: "#74BAB6",
                  borderColor: "#74BAB6",
                }}
                onClick={addNewTicket}
              >
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}
