import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { GOOGLE_MAP_API_KEY } from "src/utils/constants";
import Typography from "src/theme/overrides/Typography";

const libraries = ["places"];
const MapWithSearch = ({ setAssignedLocation, selectedPlace, setSelectedPlace }) => {
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({ lat: 22.57, lng: 88.36 });
  const [markerPosition, setMarkerPosition] = useState(null);
  const [address, setAddress] = useState("");
  // const [selectedPlace, setSelectedPlace] = useState(null);

  // Handle map load event
  const handleLoad = (map) => {
    setMap(map);
  };

  // Handle map click event to move the marker
  const handleMapClick = (event) => {
    const latLng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    console.log("Clicked position:", latLng.lat, latLng.lng);
    setMarkerPosition(latLng);
    setCenter(latLng);
    setAssignedLocation({
      type: "POINT",
      coordinates: [latLng.lat, latLng.lng],
    });
  };

  // Handle place selection from the search bar
  const handleSelect = async (value) => {
    setAddress(value);
    setSelectedPlace(null);
    console.log(address, 'bal bara');
    try {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      setCenter(latLng);
      setMarkerPosition(latLng);
    } catch (error) {
      console.error("Error fetching geolocation:", error);
    }
  };
  useEffect(() => {
    // Function to get user's current location
    const getCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCenter({ lat: latitude, lng: longitude });
            setMarkerPosition({ lat: latitude, lng: longitude });
          },
          (error) => {
            console.error("Error getting user location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    // Call the function to get user's current location
    getCurrentLocation();
  }, []);
  const handlePlaceSelect = async (place) => {
    console.log(place, "place");
    setSelectedPlace(place?.formattedSuggestion?.mainText);
    setAddress(place.description);
    try {
      // const results = await geocodeByAddress(place.description);
      // const latLng = await getLatLng(results[0]);
      const placeDetails = await geocodeByPlaceId(place.placeId);
      const latLng = await getLatLng(placeDetails[0]);
      console.log(latLng,'bal');
      setCenter(latLng);
      setMarkerPosition(latLng);
      setAssignedLocation({
        type: "POINT",
        coordinates: [latLng.lat, latLng.lng],
      });
    } catch (error) {
      console.error("Error fetching geolocation:", error);
    }
    setAddress(""); // Clear the address input value to close the search bar
    const inputElement = document.getElementById("places-autocomplete-input");
    if (inputElement) {
      inputElement.blur(); // Programmatically blur the input field to close dropdown
    }
  };

  return (
    <>
    
    <LoadScript
      googleMapsApiKey={GOOGLE_MAP_API_KEY}
      libraries={libraries}
      loading="async"
    >
     
      <GoogleMap
        mapContainerStyle={{ height: "85%", width: "100%", borderRadius:'15px' }}
        center={center}
        zoom={12}
        onClick={handleMapClick}
        onLoad={handleLoad}
      >
        {markerPosition && <Marker position={markerPosition} />}
        <div
          style={{
            position: "absolute",
            top: "3px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
          }}
        >
          
          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  {...getInputProps({ placeholder: "Search Places..." })}
                  id="places-autocomplete-input"
                />
                <div>
                  {loading ? <div>Loading...</div> : null}
                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                    };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, { style })}
                        onClick={() => handlePlaceSelect(suggestion)}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        {selectedPlace && selectedPlace.geometry && (
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1,
            }}
          >
            <div>Selected Place: {selectedPlace.description}</div>
            <div>Lat: {selectedPlace.geometry?.location?.lat()}</div>
            <div>Lng: {selectedPlace.geometry?.location?.lng()}</div>
          </div>
        )}
      </GoogleMap>
    </LoadScript>
    </>
  );
};

export default MapWithSearch;
