import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";

import UserListContext from "src/context/User/User.Context.Provider";

const UserFilterDialog = ({ onClose }) => {
  const {
    setPage,
    setFetchedPages,
    setFilter,
    userType,
    setUserType,
    status,
    setStatus,
  } = useContext(UserListContext);

  const userTypeList = ["ADMIN", "PHOTOGRAPHER", "EDITOR"];
  const ActiveInactive = ["Active", "Inactive"];
  const [activeStatus, setActiveStatus] = useState("");
  const handleStatus = (newValue) => {
    if (newValue === "Active") {
      setActiveStatus("Active");
    } else if (newValue === "Inactive") {
      setActiveStatus("Inactive");
    } else setActiveStatus("");
  };

  const handleApply = async () => {
    let append = "";
    if (userType) {
      append += `${userType}`;
    }
    if (activeStatus === "Active") {
      setStatus("true");
    } else if (activeStatus === "Inactive") {
      setStatus("false");
    } else {
      setStatus("");
    }
    console.log(status, activeStatus);
    console.log(append);
    setFilter(append);

    setPage(0);
    setFetchedPages(1);
    toast("Filter applied successfully", {
      position: "top-right",
      autoClose: 3579,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      type: "success",
    });
    onClose();
  };
  const cancelHandler = () => {
    onClose();
    setUserType("");
    setStatus("");
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            id="userType"
            onChange={(e, newValue) => setUserType(newValue)}
            fullWidth
            required
            name="role"
            value={userType || ""}
            options={userTypeList || []}
            renderInput={(params) => (
              <TextField {...params} label="Role" required />
            )}
            isOptionEqualToValue={(option, value) =>
              value === undefined || value === "" || option?._id === value?._id
            }
            sx={{
              "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
                {
                  backgroundColor: "#fff",
                },
              "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'].Mui-focused":
                {
                  backgroundColor: "#f6f6f6",
                },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            onChange={(e, newValue) => {
              handleStatus(newValue);
            }}
            value={activeStatus}
            name="isActive"
            options={ActiveInactive}
            renderInput={(params) => (
              <TextField {...params} label="Status" required />
            )}
            isOptionEqualToValue={(option, value) =>
              value === undefined || value === "" || option?._id === value?._id
            }
            sx={{
              "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
                {
                  backgroundColor: "#fff",
                },
              "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'].Mui-focused":
                {
                  backgroundColor: "#f6f6f6",
                },
            }}
          />
        </Grid>
      </Grid>
      <Box position="absolute" right="25px" bottom="25px">
        <Button
          onClick={cancelHandler}
          variant="outlined"
          style={{
            backgroundColor: "white",
            color: "#EFA8B0",
            borderColor: "#EFA8B0",
            marginRight: "4px",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          style={{
            backgroundColor: "white",
            color: "#74BAB6",
            borderColor: "#74BAB6",
          }}
          onClick={handleApply}
        >
          Apply
        </Button>
      </Box>
    </>
  );
};

export default UserFilterDialog;
