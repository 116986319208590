import { createApi, getDetailsApi, getTicketDetailsApi, updateApi } from "./api.request";

const TICKET_PATH = "/apis/ticket";

export const getTickets = async (search, limit, offset, status) =>
  getTicketDetailsApi(`${TICKET_PATH}`, search, limit, offset, status);

export const createTicket = async (body) => createApi(`${TICKET_PATH}`, body);

export const updateTicketStatus = async (ticketId, body) =>
  updateApi(`${TICKET_PATH}/status/${ticketId}`,  body);

export const updateResolutionStatus = async (ticketId, body) =>
  updateApi(`${TICKET_PATH}/resolution/${ticketId}`, body);

export const viewTicket = async (ticketId) => getDetailsApi(`${TICKET_PATH}/${ticketId}`);
