import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import { viewClient } from "src/service/clients.service";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SkeletonLoader from "src/components/skeleton/Skeleton.Loader";

export default function Clientview() {
  const { clientId } = useParams();
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const { data } = await viewClient(clientId);
    setInputs(data?.data || {});
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Helmet>
        <title>
          {" "}
          {inputs?.name?.split(" ")[0] || "Client"}'s detail | Camories{" "}
        </title>
      </Helmet>
      <Card key={inputs?._id} variant="outlined" sx={{ background: "#f1f2f6" }}>
        <CardContent>
          <Typography variant="h4" marginBottom={3} color="primary">
            Client Details
          </Typography>
          {isLoading ? (
            <SkeletonLoader />
          ) : (
            <Card>
              <CardContent>
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "right" }}>
                    <Button
                      variant="contained"
                      sx={{ mt: 2, mb: 3, mr: 2, py: 1, px: 2 }}
                      onClick={() => navigate(-1)}
                      style={{
                        backgroundColor: "white",
                        color: "#EFA8B0",
                        borderColor: "#EFA8B0",
                      }}
                    >
                      <ArrowBackIcon />
                      Back
                    </Button>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.uhid || ""}
                        disabled
                        name="uhid"
                        label="UHID"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.phoneNo || ""}
                        disabled
                        name="phoneNo"
                        label="Phone Number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.name || ""}
                        disabled
                        name="name"
                        label="Client Name"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.email || ""}
                        disabled
                        name="email"
                        label="E-mail"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          )}
        </CardContent>
      </Card>
    </>
  );
}
