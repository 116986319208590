const {
  getSalesAnalyticsApi,
  getSalesAmountAnalyticsApi,
  getSalesPackageAnalyticsApi,
  getAnalyticsDetailsApi,
} = require("./api.request");

const AMOUNT_ANALYTIC_PATH = "/apis/analytics";
const PACKAGE_ANALYTIC_PATH = "/apis/analytics";

export const getSalesAmount = async (fromDate, toDate, status) =>
  getSalesAmountAnalyticsApi(
    `${AMOUNT_ANALYTIC_PATH}/sales-amount?`,
    fromDate,
    toDate,
    status
  );

export const getSalesPackage = async (fromDate, toDate, status) =>
  getSalesPackageAnalyticsApi(
    `${PACKAGE_ANALYTIC_PATH}/sales-package?`,
    fromDate,
    toDate,
    status
  );
export const getOverallAnalytics = async () =>
  getAnalyticsDetailsApi(`${PACKAGE_ANALYTIC_PATH}/sales-overall`);
