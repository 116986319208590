import { Box, Grid, Skeleton, Stack } from "@mui/material";
import React, { useEffect } from "react";

export default function SkeletonLoader() {
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const randomInt = getRandomInt(2, 8);
  const skeletons = new Array(randomInt).fill(null);
  useEffect(() => {}, []);
  return (
    <Stack spacing={1} padding={2}>
      <Skeleton />
      <Skeleton animation="wave" />
      <Box
        sx={{
          display: "flex",
          gap: 10,
          justifyContent: "space-between",
          marginBlock: 2,
        }}
      >
        <Skeleton variant="rounded" width={"100%"} height={100} />
      </Box>
      <Grid container spacing={2}>
        {skeletons.map((_, index) => (
          <Grid item xs={6} key={index}>
            <Skeleton
              variant="rounded"
              animation="wave"
              width={400}
              height={50}
            />
          </Grid>
        ))}
      </Grid>
      <Skeleton
        sx={{ margin: 10 }}
        variant="rounded"
        animation="wave"
        width={"100%"}
        height={60}
      />
      {skeletons.map((_, index) => (
        <Grid item xs={6} key={index}>
          <Skeleton variant="text" animation="wave" sx={{ fontSize: "1rem" }} />
        </Grid>
      ))}

      <Box
        sx={{ display: "flex", gap: 10, justifyContent: "flex-end", margin: 2 }}
      >
        <Skeleton variant="rounded" width={210} height={60} />
      </Box>
    </Stack>
  );
}
