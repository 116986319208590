/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from "react-helmet-async";
import * as React from "react";
import { sentenceCase } from "change-case";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Paper,
  Popover,
  MenuItem,
  IconButton,
  Box,
  CircularProgress,
  Dialog,
} from "@mui/material";
import Slide from "@mui/material/Slide";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// components
import Label from "src/components/Label";
import Scrollbar from "src/components/Scrollbar";
import Iconify from "src/components/Iconify";

import { UserListHead, UserListToolbar } from "src/sections/@dashboard/user";

import { CURRENT_USER } from "src/utils/constants";
import NewUserDialog from "../../pages/user/User.Create.Dialog.Content";
import { deleteUser, isEnabledUser } from "src/service/user.service";

import UserListContext from "src/context/User/User.Context.Provider";
import { toast } from "react-toastify";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis?.map((el) => el[0]);
}

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

function GradientCircularProgress() {
  return (
    <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
      />
    </React.Fragment>
  );
}

export default function User() {
  const loginData =
    JSON.parse(localStorage.getItem(CURRENT_USER)) ||
    JSON.parse(sessionStorage.getItem(CURRENT_USER));

  const TABLE_HEAD = [
    { id: "employeeCode", label: "Employee Code", alignRight: false },
    { id: "fullName", label: "Full Name", alignRight: false },
    { id: "role", label: "Role", alignRight: false },
    { id: "status", label: "Status", alignRight: false },
    { id: "action", label: "Actions", alignCenter: true },
  ];

  const {
    userData,
    setUserData,
    fetchData,
    pagination,
    rowsPerPage,
    fetchedPages,
    page,
    setPage,
    status,
    setFetchedPages,
    filter,
    search,
    setSearch,
    isLoading,
    setSelectedIndex,
  } = useContext(UserListContext);

  const [open, setOpen] = useState(null);

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("fullName");

  const [dialogOpen, setDialogOpen] = useState(false);

  const [selectedUserId, setSelectedUserId] = useState("");

  const [enable, setEnable] = useState();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialogDelete = () => {
    setIsDialogOpen(true);
    console.log("testing dialog open");
  };

  const handleCloseDialogDelete = () => {
    setIsDialogOpen(false);
    handleCloseMenu();
    console.log("testing dialog delete");
  };

  const handleEdit = (selectedUserId) => {
    console.log(selectedUserId);
    navigate(`/users/${selectedUserId}/edit`);
  };

  const handleEnable = async (selectedUserId) => {
    setEnable(!enable);
    await isEnabledUser(selectedUserId, { enable: !enable });
    handleCloseMenu();
    fetchData("", "", page * rowsPerPage, "");
    setPage(0);
    setFetchedPages(1);
  };

  const handleDelete = async (selectedUserId) => {
    const { data } = await deleteUser(selectedUserId);
    if (data?.code === 200) {
      toast.success("User Deleted Successfully.");
      handleCloseMenu();
      fetchData("", "", page * rowsPerPage, "", true);
      setPage(0);
      setFetchedPages(1);
      // if(page >0 && pagination?.total%(page*rowsPerPage)===1){
      //   setPage((prevPage)=>prevPage-1)
      // }
      handleCloseDialogDelete();
    } else {
      toast.error("There is an error in user deletion");
    }
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
    console.log("testing dialog open new user");
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const navigate = useNavigate();

  const handleOpenMenu = (event, row, index) => {
    setOpen(event.currentTarget);
    setSelectedUserId(row?._id);
    setEnable(row?.isActive);
    setSelectedIndex(index);
    console.log(index, "this is selected");
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage, "newPage");
    console.log(page, "previousPage");
    setPage(newPage);
    if (newPage > fetchedPages - 1) {
      setFetchedPages((value) => value + 1);
    }
    console.log(fetchedPages, "fetchedPages");
  };

  // console.log(userData, 'userData');
  const handleSearch = (event) => {
    setPage(0);
    setFetchedPages(1);
    setSearch(event.target.value);
  };

  const handleClickRow = (row) => {
    console.log(row?._id);
    navigate(`/users/${row?._id}`);
  };
  console.log(userData, "user in useState");
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userData?.length) : 0;
  const filteredUsers = applySortFilter(
    userData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    getComparator(order, orderBy)
  );

  const isNotFound = !userData?.length && !!search;
  console.log(page * rowsPerPage, "filterdusers start index");
  console.log(page * rowsPerPage + rowsPerPage, "filterdusers end index");
  console.log(filteredUsers, "filterdusers");

  useEffect(() => {
    const fetchDataWithDebounce = () => {
      fetchData(search, filter, page * rowsPerPage, status);
    };

    let timer;
    console.count("test surveyList useEffect");

    const debounce = () => {
      timer = setTimeout(fetchDataWithDebounce, 600);
    };

    const noDebounce = () => {
      if (fetchedPages === 1 || page >= fetchedPages - 1) {
        fetchDataWithDebounce();
      }
    };

    if (search) debounce();
    else noDebounce();

    return () => {
      clearTimeout(timer);
    };
  }, [search, filter, status, fetchedPages]);
  // [ search, filter, status, page]
  return (
    <>
      <Helmet>
        <title> Users | Camories </title>
      </Helmet>
      <Container maxWidth={false}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h4" gutterBottom color="primary">
            {/* User */}
          </Typography>
          {loginData?.role === "ADMIN" && (
            <Button
              variant="contained"
              style={{ backgroundColor: "#74BAB6", color: "white" }}
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleDialogOpen}
            >
              New User
            </Button>
          )}
          <NewUserDialog
            dialogOpen={dialogOpen}
            handleDialogClose={handleDialogClose}
            setUserData={setUserData}
            fetchData={fetchData}
            setFetchedPages={setFetchedPages}
            setPage={setPage}
          />
        </Stack>

        <Card>
          <UserListToolbar
            setFetchedPages={setFetchedPages}
            setPage={setPage}
            search={search}
            onSearch={handleSearch}
            fetchData={fetchData}
          />

          <Scrollbar>
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "300px",
                }}
              >
                <GradientCircularProgress />
              </Box>
            ) : userData?.length > 0 || isNotFound ? (
              <TableContainer sx={{ minWidth: 800, overflow: "hidden" }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    onRequestSort={handleRequestSort}
                  />

                  <TableBody>
                    {filteredUsers?.map((row, index) => {
                      const { _id, empCode, fullName, role, isActive } = row;
                      // console.log(filteredUsers, 'bal');

                      return (
                        <TableRow hover key={_id} tabIndex={-1}>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                handleClickRow(row);
                              }}
                            >
                              {empCode}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleClickRow(row);
                                }}
                              >
                                {fullName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{role}</TableCell>
                          <TableCell align="left">
                            <Label
                              color={
                                (isActive === false && "error") || "success"
                              }
                            >
                              {sentenceCase(isActive ? "Active" : "Inactive")}
                            </Label>
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) =>
                                handleOpenMenu(event, row, index)
                              }
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow sx={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{search}&quot;</strong>.
                              <br /> Try checking for typos or using complete
                              words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4">Data not available.</Typography>
              </Box>
            )}
          </Scrollbar>
          {!isLoading && filteredUsers?.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={pagination?.total || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          )}
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={() => handleEdit(selectedUserId)}>Edit</MenuItem>

        <MenuItem sx={{ color: "error.main" }}>
          <>
            <Grid
              item
              xs={10}
              sx={{
                display: "flex",
                alignItems: "center",
                color: "black",
              }}
              onClick={() => handleEnable(selectedUserId)}
            >
              {enable ? "Disable" : "Enable"}
            </Grid>
          </>
        </MenuItem>
        <MenuItem sx={{ color: "error.main" }} onClick={handleOpenDialogDelete}>
          Delete
        </MenuItem>
      </Popover>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialogDelete}
        TransitionComponent={Transition}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialogDelete}
            variant="outlined"
            style={{
              backgroundColor: "white",
              color: "#EFA8B0",
              borderColor: "#EFA8B0",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            style={{
              backgroundColor: "white",
              color: "#74BAB6",
              borderColor: "#74BAB6",
            }}
            onClick={() => handleDelete(selectedUserId)}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
