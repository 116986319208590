import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import OrderContext from "src/context/Order/Order.Context.Provider";
import { getHospitals } from "src/service/hospital.service";

export default function OrderFilterDialogContent({ onClose }) {
  const {
    setPage,
    setFetchedPages,
    setStatus,
    hospital,
    setHospital,
    deliveryDate,
    setDeliveryDate,
    softCopyDeliveryDate,
    setSoftCopyDeliveryDate,
  } = useContext(OrderContext);
  const filteredStatusList = [
    "Pending",
    "Soft Copy Delivered",
    "Frame Sent To Hospital",
    "Frame Received In Hospital",
  ];
  const [activeStatus, setActiveStatus] = useState("");
  const [frameDeliverydate, setFrameDeliveryDate] = useState(null);
  const [softCopyDelivery, setSoftCopyDelivery] = useState(null);
  const [hospitalId, setHospitalId] = useState("");
  const [hospitalList, setHospitalList] = useState([]);

  const handleApply = () => {
    if (activeStatus === "Pending") {
      setStatus("Pending");
    } else if (activeStatus === "Soft Copy Delivered") {
      setStatus("Soft Copy Delivered");
    } else if (activeStatus === "Frame Sent To Hospital") {
      setStatus("Frame Sent To Hospital");
    } else if (activeStatus === "Frame Received In Hospital") {
      setStatus("Frame Received In Hospital");
    } else {
      setStatus("");
    }
    if (frameDeliverydate !== null) {
      setDeliveryDate(frameDeliverydate.valueOf());
    }
    if (softCopyDelivery !== null) {
      setSoftCopyDeliveryDate(softCopyDelivery.valueOf());
    }
    if (hospitalId) {
      setHospital(hospitalId?._id);
    }

    // setPage(0);
    // setFetchedPages(1);
    toast("Filter applied successfully", {
      position: "top-right",
      autoClose: 3579,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      type: "success",
    });
    onClose();
  };

  const getHospitalsList = async () => {
    const { data } = await getHospitals("", -1, 0, "");
    setHospitalList(data?.data?.items);
  };
  useEffect(() => {
    getHospitalsList();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Grid item xs={12} marginBottom={2}>
          <Autocomplete
            onChange={(e, newValue) => setActiveStatus(newValue)}
            fullWidth
            value={activeStatus || ""}
            options={filteredStatusList || []}
            renderInput={(params) => (
              <TextField {...params} label="Order Status" />
            )}
            getOptionLabel={(options) => options}
          />
        </Grid>

        <Grid item xs={12} marginBottom={2}>
          <Autocomplete
            name="hospital"
            onChange={(e, newValue) => setHospitalId(newValue)}
            fullWidth
            value={hospitalId || ""}
            options={hospitalList || []}
            renderInput={(params) => (
              <TextField {...params} label="Hospitals" required />
            )}
            getOptionLabel={(options) =>
              options?._id ? ` ${options?.name}` : ""
            }
            isOptionEqualToValue={(option, value) =>
              value === undefined || value === "" || option?._id === value?._id
            }
            getOptionSelected={(option, value) => option?._id === value?._id}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} marginBottom={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Frame delivery date"
                value={frameDeliverydate}
                name="startDate"
                format="DD/MM/YYYY"
                onChange={(newStartDate) => {
                  if (newStartDate)
                    return setFrameDeliveryDate(
                      dayjs(newStartDate).startOf("day")
                    );
                  return setFrameDeliveryDate(null);
                }}
                //   sx={{ width: "100% !important" }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} marginBottom={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Soft Copy delivery date"
                value={softCopyDelivery}
                name="startDate"
                format="DD/MM/YYYY"
                onChange={(newStartDate) => {
                  if (newStartDate)
                    return setSoftCopyDelivery(
                      dayjs(newStartDate).startOf("day")
                    );
                  return setSoftCopyDelivery(null);
                }}
                //   sx={{ width: "100% !important" }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
      <Box position="absolute" right="25px" bottom="25px">
        <Button
          onClick={onClose}
          variant="outlined"
          style={{
            backgroundColor: "white",
            color: "#EFA8B0",
            borderColor: "#EFA8B0",
            marginRight: "4px",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          style={{
            backgroundColor: "white",
            color: "#74BAB6",
            borderColor: "#74BAB6",
          }}
          onClick={handleApply}
        >
          Apply
        </Button>
      </Box>
    </>
  );
}
