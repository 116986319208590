import { createApi, deleteApi, getDetailsApi, getHospitalsApi, isEnabledApi, updateApi,  } from "./api.request";

const HOSPITAL_PATH = '/apis/hospital';

export const createHospital = async (body)=> createApi(`${HOSPITAL_PATH}`, body);

export const getHospitals = async(search, limit, offset, status)=> getHospitalsApi(`${HOSPITAL_PATH}/search`, search, limit, offset, status );

export const deleteHospital = async(hospitalId) => deleteApi(`${HOSPITAL_PATH}/${hospitalId}`);

export const isEnabledHospital = async (hospitalId, body) => isEnabledApi(`${HOSPITAL_PATH}/${hospitalId}/enable`, body);

export const viewHospital = async (hospitalId)=> getDetailsApi(`${HOSPITAL_PATH}/${hospitalId}`);

export const updateHospital = async(hospitalId, body)=> updateApi(`${HOSPITAL_PATH}/${hospitalId}`, body);


