import { getOrders } from "src/service/order.service";

const { createContext, useState } = require("react");
const OrderContext = createContext();
export const OrderContetxProvider = ({ children }) => {
  const [orderData, setOrderData] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(0);
  const [fetchedPages, setFetchedPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hospital, setHospital] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [softCopyDeliveryDate, setSoftCopyDeliveryDate] = useState("");

  const fetchData = async (
    filterName,
    offset = 0,
    statusFilter,
    hospitalId,
    frameDeliveryDate,
    softCopyDelivery
  ) => {
    setIsLoading(true);
    const { data } = await getOrders(
      filterName,
      rowsPerPage,
      offset,
      statusFilter,
      hospitalId,
      frameDeliveryDate,
      softCopyDelivery
    );
    setIsLoading(false);
    if (data?.data?.pagination?.offset === "0") setOrderData(data?.data?.items);
    else {
      setOrderData((preValue) => {
        console.log("trying to get Order array", [
          ...preValue,
          ...data?.data?.items,
        ]);
        return [...preValue, ...data?.data?.items];
      });
    }
    console.log(data?.data?.shoots);

    setPagination(data?.data?.pagination);
  };
  return (
    <OrderContext.Provider
      value={{
        orderData,
        setOrderData,
        fetchData,
        pagination,
        setPagination,
        rowsPerPage,
        setRowsPerPage,
        fetchedPages,
        page,
        setPage,
        setFetchedPages,
        status,
        setStatus,
        filter,
        setFilter,
        search,
        setSearch,
        isLoading,
        hospital,
        setHospital,
        deliveryDate,
        setDeliveryDate,
        softCopyDeliveryDate,
        setSoftCopyDeliveryDate,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
export default OrderContext;
