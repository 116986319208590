import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import Iconify from "../../components/Iconify";

import Stack from "@mui/material/Stack";
import { viewHospital } from "src/service/hospital.service";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SkeletonLoader from "src/components/skeleton/Skeleton.Loader";
export default function Hospitalview() {
  const { hospitalId } = useParams();
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const { data } = await viewHospital(hospitalId);
    setInputs(data?.data || {});
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = () => {
    console.log(hospitalId);
    navigate(`/hospitals/${hospitalId}/edit`);
  };

  return (
    <>
      <Helmet>
        <title>
          {" "}
          {inputs?.name?.split(" ")[0] || "Hospital"}'s detail | Camories{" "}
        </title>
      </Helmet>
      <Card key={inputs?._id} variant="outlined" sx={{ background: "#f1f2f6" }}>
        <CardContent>
          <Typography variant="h4" marginBottom={3} color="primary">
            Hospital Details
          </Typography>
          {isLoading ? (
            <SkeletonLoader />
          ) : (
            <Card>
              <CardContent>
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "right" }}>
                    <Button
                      variant="contained"
                      sx={{ mt: 2, mb: 3, mr: 2, py: 1, px: 2 }}
                      onClick={() => navigate(-1)}
                      style={{
                        backgroundColor: "white",
                        color: "#EFA8B0",
                        borderColor: "#EFA8B0",
                      }}
                    >
                      <ArrowBackIcon />
                      Back
                    </Button>

                    <Button
                      variant="contained"
                      startIcon={<Iconify icon="eva:edit-fill" />}
                      sx={{ mt: 2, mb: 3, py: 1, px: 2, float: "right" }}
                      style={{
                        backgroundColor: "white",
                        color: "#74BAB6",
                        borderColor: "#74BAB6",
                      }}
                      onClick={handleEdit}
                    >
                      Edit
                    </Button>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        value={inputs?.name || ""}
                        disabled
                        name="empCode"
                        label="Hospital Name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.address || ""}
                        disabled
                        name="fullName"
                        label="Full Address"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.contactNumber || "- NA -"}
                        disabled
                        name="email"
                        label="Contact Number"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          )}
        </CardContent>
      </Card>
    </>
  );
}
