import { getShoots } from "src/service/shoots.service";

const { createContext, useState } = require("react");

const ShootsContext = createContext();
export const ShootsContextProvider = ({ children }) => {
  const [shootData, setShootData] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(0);
  const [fetchedPages, setFetchedPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState("");
  const [hospital, setHospital] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const fetchData = async (
    filterName,
    startDate,
    dueDate,
    offset = 0,
    statusFilter,
    hospitalId
  ) => {
    setIsLoading(true);
    const { data } = await getShoots(
      filterName,
      startDate,
      dueDate,
      rowsPerPage,
      offset,
      statusFilter,
      hospitalId
    );
    setIsLoading(false);
    if (data?.data?.pagination?.offset === "0") setShootData(data?.data?.items);
    else {
      setShootData((preValue) => {
        console.log("trying to get Shoots array", [
          ...preValue,
          ...data?.data?.items,
        ]);
        return [...preValue, ...data?.data?.items];
      });
    }
    console.log(data?.data?.shoots);

    setPagination(data?.data?.pagination);
  };
  return (
    <ShootsContext.Provider
      value={{
        shootData,
        setShootData,
        fetchData,
        pagination,
        setPagination,
        rowsPerPage,
        setRowsPerPage,
        fetchedPages,
        page,
        setPage,
        setFetchedPages,
        status,
        setStatus,
        filter,
        setFilter,
        search,
        setSearch,
        isLoading,
        fromDate,
        toDate,
        hospital,
        setHospital,
        setFromDate,
        setToDate,
      }}
    >
      {children}
    </ShootsContext.Provider>
  );
};
export default ShootsContext;
