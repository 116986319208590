import React from "react";
import { Helmet } from "react-helmet-async";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import Iconify from "src/components/Iconify";
import { updateHospital, viewHospital } from "src/service/hospital.service";
import { toast } from "react-toastify";
import SkeletonLoader from "src/components/skeleton/Skeleton.Loader";

export default function Hospitaledit() {
  const { hospitalId } = useParams();

  const navigate = useNavigate();

  const [inputs, setInputs] = useState({});
  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  inputs.isActive = status;

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleStatus = (newValue) => {
    if (newValue === "Active") {
      setStatus(true);
    } else if (newValue === "Inactive") {
      setStatus(false);
    } else setStatus((value) => value);
  };

  //   const { error, value } = Schema.validate(inputs);

  const handleCancel = () => {
    // navigate('/users');
    navigate(-1);
  };

  const updateData = async () => {
    const { data } = await updateHospital(hospitalId, inputs);
    if (data?.code === 200) {
      navigate("/hospitals");
      toast.success("Hospital's data Updated Successfully");
    } else {
      toast.error("Data Not Updated");
    }
  };

  const handleSave = () => {
    // if (error) {
    //   // console.log(error);
    //   error?.details?.forEach((err) => {
    //     toast.error(err.message);
    //   });
    // } else {
    updateData();
    // }
  };

  const ActiveInactive = ["Active", "Inactive"];

  const fetchData = async () => {
    setIsLoading(true);
    const { data } = await viewHospital(hospitalId);
    // console.log(data?.data);
    setInputs(data?.data || {});
    setStatus(data?.data?.isActive || null);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {" "}
          {inputs?.name?.split(" ")[0] || "Hospital"}'s edit | Camories{" "}
        </title>
      </Helmet>
      {/* <ToastContainer autoClose={3000} style={{ zIndex: '99 !important' }} /> */}
      <Card key={inputs?._id} variant="outlined" sx={{ background: "#f1f2f6" }}>
        <CardContent>
          <Typography variant="h4" marginBottom={3} color="primary">
            Edit Hospital
          </Typography>
          {isLoading ? (
            <SkeletonLoader />
          ) : (
            <Card>
              <CardContent>
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "right" }}>
                    <Button
                      variant="contained"
                      startIcon={<Iconify icon="ic:sharp-cancel" />}
                      sx={{ mt: 2, mb: 3, mr: 2, py: 1, px: 2 }}
                      style={{
                        backgroundColor: "white",
                        color: "#EFA8B0",
                        borderColor: "#EFA8B0",
                      }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<Iconify icon="ic:sharp-save" />}
                      sx={{ mt: 2, mb: 3, py: 1, px: 2 }}
                      style={{
                        backgroundColor: "white",
                        color: "#74BAB6",
                        borderColor: "#74BAB6",
                      }}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        onChange={handleInput}
                        value={inputs?.name || ""}
                        name="name"
                        label="Hospital Name"
                        fullWidth
                        required
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        onChange={handleInput}
                        value={inputs?.address || ""}
                        name="address"
                        label="Full Address"
                        fullWidth
                        required
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        onChange={handleInput}
                        value={inputs?.contactNumber || ""}
                        name="contactNumber"
                        label="Contact Number"
                        fullWidth
                        required
                        autoComplete="off"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        onChange={(e, newValue) => {
                          handleStatus(newValue);
                        }}
                        value={status ? "Active" : "Inactive"}
                        name="isActive"
                        options={ActiveInactive}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status"
                            required
                            autoComplete="off"
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          value === undefined ||
                          value === "" ||
                          option === value
                        }
                        sx={{
                          marginBottom: "20px",
                          "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
                            {
                              backgroundColor: "#fff",
                            },
                          "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'].Mui-focused":
                            {
                              backgroundColor: "#f6f6f6",
                            },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          )}
        </CardContent>
      </Card>
    </>
  );
}
