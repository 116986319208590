const Joi = require("joi");

const Schema = Joi.object({
  name: Joi.string()
    .messages({
      "any.required": `Name is a required field.`,
      "string.empty": `Name must contain value.`,
    })
    .required(),
  address: Joi.string()
    .messages({
      "any.required": `Address is a required field.`,
      "string.empty": `Address must contain value.`,
    })
    .required(),
  contactNumber: Joi.string()
    .regex(/^[\d]{10}$/)
    .min(10)
    .max(10)
    .messages({
      "any.required": `Contact Number is a required field.`,
      "string.min": "Number must be exactly 10 digits.",
      "string.max": "Number must be exactly 10 digits.",
      "string.empty": `Contact Number must contain value.`,
      "string.pattern.base": "Contact Number must be exactly 10 digits.",
    }),
});
export default Schema;
